import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { getCheckList, updateCheckList } from "../../actions/checkListActions";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearCheckListUpdated, clearError } from "../../slices/checkListSlice";

const UpdateCheckList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: checkListId } = useParams();
  const { isCheckListUpdated, checkList, error } = useSelector(
    (state) => state.checkListState
  );

  const [formData, setFormData] = useState({
    orderDate: "",
    targetDate: "",
    businessName: "",
    businessFormat: "",
    placeOfBusiness: "",
    state: "",
    businessActivity: "",
    doi: "",
    yoa:"",
    isThisYearsAuditorTheSame: false,
    isThisYearsAuditorTheSameRemarks: "",
    ifNoPreviousAuditor: "",
    currentYearAuditor: "",
    currentYearAuditorPeriod: "",
    currentYearAuditorRenewalDue: false,
    currentYearAuditorRenewalSRN: false,
    filedByPreAuditor: false,
    filedByPreAuditorRemark: "",
    srnNumberADT3: "",
    formADT3Verified: false,
    formADT3VerifiedRemarks: "",
    nocReceivedFromPreAuditor: false,
    nocReceivedFromPreAuditorRemark: "",
    formADT1Filed: false,
    formADT1FiledRemark: "",
    srnNumberADT1: "",
    formADT1Verified: false,
    formADT1VerifiedRemark: "",

    formAOC4Filed: false,
    formAOC4FiledRemark: "",
    //
    formMGT7AFiled: false,
    formMGT7AFiledRemark: "",

    pyAuditedFinancialsGSTVerified: false,
    pyAuditedFinancialsGSTVerifiedRemark: "",

    formINC20AFiled: false,
    formINC20AFiledRemark: "",
    formINC20AFiledSRN: "",

    numberOfBankAccounts: "",
    numberOfBankAccountsReceived: "",

    statementsCoverWholeYear: false,
    statementsCoverWholeYearRemark: "",

    openingBalancesMatchingPY: false,
    openingBalancesMatchingPYRemark: "",

    hasRegisteredUnderGST: false,
    hasRegisteredUnderGSTRemark: "",
    gstLoginCredentialsUsername: "",
    gstLoginCredentialsPassword: "",

    hasRegisteredUnderIncomeTax: false,
    hasRegisteredUnderIncomeTaxRemark: "",
    incomeTaxLoginCredentialsUsername: "",
    incomeTaxLoginCredentialsPassword: "",

    hasRegisteredUnderESI: false,
    hasRegisteredUnderESIRemark: "",
    esiLoginCredentialsUsername: "",
    esiLoginCredentialsPassword: "",

    hasRegisteredUnderPF: false,
    hasRegisteredUnderPFRemark: "",
    pfLoginCredentialsUsername: "",
    pfLoginCredentialsPassword: "",

    hasRegisteredUnderTRACES: false,
    hasRegisteredUnderTRACESRemark: "",
    tracesLoginCredentialsUsername: "",
    tracesLoginCredentialsPassword: "",

    goodsAndServicesDealing: "",
    salesTurnoverReconciliation: "",
    purchaseTurnoverReconciliation: "",
    gstInputOutputReconciliation: "",

    anyExportImportProceeds: false,
    anyExportImportProceedsRemark: "",

    haveAnyExportsTurnover: false,
    haveAnyExportsTurnoverRemark: "",

    haveAppliedForLUT: false,
    haveAppliedForLUTRemark: "",

    copyOfLUTToBeObtained: "",
    delayedPaymentsToCreditors: "",
    gstInputOnDelayedTransactions: "",

    anyTDSDeductionsDuringYear: false,
    anyTDSDeductionsDuringYearRemark: "",

    preparedTDSTCSReconciliation: false,
    preparedTDSTCSReconciliationRemark: "",

    anyTDSTCSLowerDeductionObserved: false,
    tdsTCSDetailsLowerDeduction: "",
    anyTDSTCSLowerDeductionObservedRemark: "",

    anyTDSTCSNonDeductionObserved: false,
    tdsTCSNonDeductionDetails: "",
    tdsTCSNonDeductionDetailsRemark: "",

    anyTDSTCSNonPaymentsObserved: false,
    tdsTCSNonPaymentsDetails: "",
    anyTDSTCSNonPaymentsObservedRemark: "",

    anyDisallowanceBasedOnAbove: false,
    disallowanceDetails: "",
    anyDisallowanceBasedOnAboveRemark: "",

    checkIncomeAsPer26ASAccounts: false,
    checkIncomeAsPer26ASAccountsRemark: "",

    tdsTCSCreditMatching: false,
    tdsTCSCreditMatchingRemark: "",

    additionalReportingAISNotReflectedInAccounts: false,
    additionalReportingDetails: "",
    additionalReportingAISNotReflectedInAccountsRemark: "",

    anyPurchasesDuringYear: false,
    invoicesForFixedAssetsVerified: false,
    anyPurchasesDuringYearRemark: "",

    checkGSTInputCreditUtilisation: false,
    checkGSTInputCreditUtilisationRemark: "",

    anySaleOfFixedAssetsDuringYear: false,
    invoicesForSaleOfFixedAssetsVerified: false,
    anySaleOfFixedAssetsDuringYearRemark: "",

    companyVehiclesUsedForPersonalPurposes: false,
    disallowanceOnCompanyVehicles: false,
    companyVehiclesUsedForPersonalPurposesRemark: "",

    bankLoansCount: "",
    bankAccountsLoanReceived: "",

    statementsCoverage: false,
    statementsCoverageRemark: "",

    newLoansSanctionedDuringPY: false,
    newLoansSanctionedDuringPYRemark: "",

    chargeCreationRequiredWithROC: false,
    chargeCreationRequiredWithROCRemark: "",

    chargeFormsReceivedForVerification: false,
    chargeFormsReceivedForVerificationRemark: "",
    chargeFormsSRN: "",

    bankLoanUtilisationChecked: false,
    bankLoanUtilisationCheckedRemark: "",

    otherPurposeUtilisationDetails: false,
    otherPurposeUtilisationDetailsDetais: "",
    otherPurposeUtilisationDetailsRemark: "",

    odCcLimitsUtilisedForPersonalOrLongTerm: false,
    odCcLimitsUtilisationDetails: "",
    odCcLimitsUtilisedForPersonalOrLongTermRemark: "",

    sundryDebtorsList: "",
    outstandingAmountOverSixMonths: "",
    amountOutstandingOverOneYear: "",
    verifiedAccountStatementWithDebtors: false,
    verifiedAccountStatementWithDebtorsRemark: "",

    sundryCreditorsList: "",
    outstandingAmountOverSixMonthsCreditor: "",
    amountOutstandingOverOneYearCreditor: "",
    numberOfSMEVendors: "",
    numberOfAccountsOutstandingOver15or45Days: "",
    amountOutstandingOver15or45Days: "",
    disallowanceAmountUnder44BH: "",
    verifiedAccountStatementWithCreditors: false,
    verifiedAccountStatementWithCreditorsRemark: "",

    investmentsInEntity: false,
    investmentsInEntityRemark: "",
    verifiedInvestmentDetails: "",
    investmentIncome: "",

    verifyInvestmentBalanceSheets: false,
    verifyInvestmentBalanceSheetsRemark: "",

    obtainBrokerContractNotes: false,
    obtainBrokerContractNotesRemark: "",

    obtainCapitalGainLossComputation: false,
    obtainCapitalGainLossComputationRemark: "",

    complianceWithCompaniesAct: false,
    complianceWithCompaniesActRemark: "",

    //cargo
    isCAROApplicable: false,
    reasonForApplicability: "",
    isCAROApplicableRemark: "",

    apportionmentOfPrincipalAndInterest: false,
    apportionmentOfPrincipalAndInterestRemake: "",

    tdsDeductedOnInterestPayment: false,
    tdsDeductedOnInterestPaymentRemake: "",

    properEntryInBooksOfAccounts: false,
    properEntryInBooksOfAccountsRemake: "",
    //deferred
    computationChecked: false,
    computationCheckedRemark: "",
    // loan
    loanGrantedToRelatedParty: false,
    loanGrantedToRelatedPartyRemark: "",

    loanAndAdvanceAuthorization: false,
    loanAndAdvanceAuthorizationRemark: "",

    cashReceiptsAndPaymentsToLoanAccount: false,
    cashReceiptsAndPaymentsToLoanAccountRemark: "",

    //security
    securityAgreement: false,
    securityAgreementRemark: "",

    securityRefundable: false,
    securityRefundableRemark: "",

    securityReturnAgreement: false,
    securityReturnAgreementRemark: "",

    //Cash
    obtainSignedCashBalanceCertificate: false,
    obtainSignedCashBalanceCertificateRemark: "",

    //Inventory
    receivedClosingStockConfirmation: false,
    receivedClosingStockConfirmationRemark: "",

    receivedStockLossConfirmation: false,
    receivedStockLossConfirmationRemark: "",

    revenueFromOperations: "",
    copyOfInvoices: "",

    //Purchase
    purchaseAndInvoiceDetails: "",

    //Salary
    tdsApplicabilitySalary: false,
    tdsApplicabilitySalaryRemark: "",
    tdsObservations: "",

    // esi/epf
    totalEmployeesForESIEFI: "",
    esiCoveredEmployees: "",
    registeredUnderESI: false,
    registeredUnderEPF: false,
    esiObservationsShort: "",
    esiObservationsNon: "",
    epfObservationsShort: "",
    epfObservationsNon: "",
    disallowances: "",

    //other expenses
    gstReverseChargeExpenses: "",
    personalCharityClubExpenses: "",
    regularlyOccurringExpenses: "",
    yearEndExpenseEntries: "",
    capitalNatureExpenses: "",
    gstInputClaim: "",
    gstReturnGSTR: "",
    gstReturnComparison: "",

    //Change In OwnerShip

    changesOwnershipDuringPY: false,
    changesOwnershipDuringPYRemark: "",
    detailsChangesOwnership: "",
    documentsVerificationChangesOwnership: false,
    documentsVerificationChangesOwnershipRemark: "",
    anyShareTransferAuditPeriod: false,
    anyShareTransferAuditPeriodRemark:"",
    sh4Received:false,
    sh4ReceivedRemark:"",
    noOfShareTransfer:"",
    nameTransferer:"",
    nameTransferee:"",
    changeInDirector: false,
    changeInDirectorRemark: "",
    dpt3applicable: false,
    dpt3applicableLoan: "",

    //change in management
    managementChanges: false,
    managementChangesRemark: "",
    detailsManagementChanges: "",
    verificationDocumentsManagement: false,
    verificationDocumentsManagementRemark: "",

    // GRANTS / SUBSIDIES
    receivedGrantsSubsidies: false,
    receivedGrantsSubsidiesRemark: "",
    natureGrantsSubsidies: "",
    refundable: false,
    refundableRemark: "",
    accountingTreatment: "",
    conditions: "",
    complianceConditions: "",

    accountingTreatmentGrants: "",

    // DISPUTES AND ARBITRATIONS

    pendingDisputes: false,
    pendingDisputesRemark: "",
    detailedVerification: "",
    obsRemarks_100:""
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;

    const value = e.target.type === "checkbox" ? checked : e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    await dispatch(updateCheckList(checkListId, formData));
    navigate(`/view/${checkListId}`);
  };

  useEffect(() => {
    if (isCheckListUpdated) {
      toast("CheckList Updated Successfully!", {
        type: "success",
        onOpen: () => dispatch(clearCheckListUpdated()),
      });
      return;
    }
    if (error) {
      // Dispatch the action outside of the toast function call
      dispatch(clearError());
      // Display the error message using toast
      toast(error, {
        type: "error",
      });
      return;
    }

    dispatch(getCheckList(checkListId));
  }, [isCheckListUpdated, error, dispatch]);

  useEffect(() => {
    if (checkList) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...checkList,
        orderDate: checkList.orderDate || "",
        targetDate: checkList.targetDate || "",
        businessName: checkList.businessName || "",
        businessFormat: checkList.businessFormat || "",
        placeOfBusiness: checkList.placeOfBusiness || "",
        state: checkList.state || "",
        businessActivity: checkList.businessActivity || "",
        doi: checkList.doi || "",
        yoa:checkList.yoa || "",
        yoa_100:checkList.yoa_100 || "",
        isThisYearsAuditorTheSame: checkList.isThisYearsAuditorTheSame || false,
        isThisYearsAuditorTheSameRemarks:
          checkList.isThisYearsAuditorTheSameRemarks || "",
        ifNoPreviousAuditor: checkList.ifNoPreviousAuditor || "",
        currentYearAuditor: checkList.currentYearAuditor || "",
        filedByPreAuditor: checkList.filedByPreAuditor || false,
        filedByPreAuditorRemark: checkList.filedByPreAuditorRemark || "",
        srnNumberADT3: checkList.srnNumberADT3 || "",
        formADT3Verified: checkList.formADT3Verified || false,
        formADT3VerifiedRemarks: checkList.formADT3VerifiedRemarks || "",
        nocReceivedFromPreAuditor: checkList.nocReceivedFromPreAuditor || false,
        nocReceivedFromPreAuditorRemark:
          checkList.nocReceivedFromPreAuditorRemark || "",
        formADT1Filed: checkList.formADT1Filed || false,
        formADT1FiledRemark: checkList.formADT1FiledRemark || "",
        srnNumberADT1: checkList.srnNumberADT1 || "",
        formADT1Verified: checkList.formADT1Verified || false,
        formADT1VerifiedRemark: checkList.formADT1VerifiedRemark || "",
        formAOC4Filed: checkList.formAOC4Filed || false,
        formAOC4FiledRemark: checkList.formAOC4FiledRemark || "",
        formMGT7AFiled: checkList.formMGT7AFiled || false,
        formMGT7AFiledRemark: checkList.formMGT7AFiledRemark || "",
        pyAuditedFinancialsGSTVerified:
          checkList.pyAuditedFinancialsGSTVerified || false,
        pyAuditedFinancialsGSTVerifiedRemark:
          checkList.pyAuditedFinancialsGSTVerifiedRemark || "",
        formINC20AFiled: checkList.formINC20AFiled || false,
        formINC20AFiledRemark: checkList.formINC20AFiledRemark || "",
        formINC20AFiledSRN: checkList.formINC20AFiledSRN || "",
        numberOfBankAccounts: checkList.numberOfBankAccounts || "",
        numberOfBankAccountsReceived:
          checkList.numberOfBankAccountsReceived || "",
        statementsCoverWholeYear: checkList.statementsCoverWholeYear || false,
        statementsCoverWholeYearRemark:
          checkList.statementsCoverWholeYearRemark || "",
        openingBalancesMatchingPY: checkList.openingBalancesMatchingPY || false,
        openingBalancesMatchingPYRemark:
          checkList.openingBalancesMatchingPYRemark || "",
        hasRegisteredUnderGST: checkList.hasRegisteredUnderGST || false,
        hasRegisteredUnderGSTRemark:
          checkList.hasRegisteredUnderGSTRemark || "",
        gstLoginCredentialsUsername:
          checkList.gstLoginCredentialsUsername || "",
        gstLoginCredentialsPassword:
          checkList.gstLoginCredentialsPassword || "",
        hasRegisteredUnderIncomeTax:
          checkList.hasRegisteredUnderIncomeTax || false,
        hasRegisteredUnderIncomeTaxRemark:
          checkList.hasRegisteredUnderIncomeTaxRemark || "",
        incomeTaxLoginCredentialsUsername:
          checkList.incomeTaxLoginCredentialsUsername || "",
        incomeTaxLoginCredentialsPassword:
          checkList.incomeTaxLoginCredentialsPassword || "",
        hasRegisteredUnderESI: checkList.hasRegisteredUnderESI || false,
        hasRegisteredUnderESIRemark:
          checkList.hasRegisteredUnderESIRemark || "",
        esiLoginCredentialsUsername:
          checkList.esiLoginCredentialsUsername || "",
        esiLoginCredentialsPassword:
          checkList.esiLoginCredentialsPassword || "",
        hasRegisteredUnderPF: checkList.hasRegisteredUnderPF || false,
        hasRegisteredUnderPFRemark: checkList.hasRegisteredUnderPFRemark || "",
        pfLoginCredentialsUsername: checkList.pfLoginCredentialsUsername || "",
        pfLoginCredentialsPassword: checkList.pfLoginCredentialsPassword || "",
        hasRegisteredUnderTRACES: checkList.hasRegisteredUnderTRACES || false,
        hasRegisteredUnderTRACESRemark:
          checkList.hasRegisteredUnderTRACESRemark || "",
        tracesLoginCredentialsUsername:
          checkList.tracesLoginCredentialsUsername || "",
        tracesLoginCredentialsPassword:
          checkList.tracesLoginCredentialsPassword || "",
        goodsAndServicesDealing: checkList.goodsAndServicesDealing || "",
        salesTurnoverReconciliation:
          checkList.salesTurnoverReconciliation || "",
        purchaseTurnoverReconciliation:
          checkList.purchaseTurnoverReconciliation || "",
        gstInputOutputReconciliation:
          checkList.gstInputOutputReconciliation || "",
        anyExportImportProceeds: checkList.anyExportImportProceeds || false,
        anyExportImportProceedsRemark:
          checkList.anyExportImportProceedsRemark || "",
        haveAnyExportsTurnover: checkList.haveAnyExportsTurnover || false,
        haveAnyExportsTurnoverRemark:
          checkList.haveAnyExportsTurnoverRemark || "",
        haveAppliedForLUT: checkList.haveAppliedForLUT || false,
        haveAppliedForLUTRemark: checkList.haveAppliedForLUTRemark || "",
        copyOfLUTToBeObtained: checkList.copyOfLUTToBeObtained || "",
        delayedPaymentsToCreditors: checkList.delayedPaymentsToCreditors || "",
        gstInputOnDelayedTransactions:
          checkList.gstInputOnDelayedTransactions || "",
        anyTDSDeductionsDuringYear:
          checkList.anyTDSDeductionsDuringYear || false,
        anyTDSDeductionsDuringYearRemark:
          checkList.anyTDSDeductionsDuringYearRemark || "",
        preparedTDSTCSReconciliation:
          checkList.preparedTDSTCSReconciliation || false,
        preparedTDSTCSReconciliationRemark:
          checkList.preparedTDSTCSReconciliationRemark || "",
        anyTDSTCSLowerDeductionObserved:
          checkList.anyTDSTCSLowerDeductionObserved || false,
        tdsTCSDetailsLowerDeduction:
          checkList.tdsTCSDetailsLowerDeduction || "",
        anyTDSTCSLowerDeductionObservedRemark:
          checkList.anyTDSTCSLowerDeductionObservedRemark || "",
        anyTDSTCSNonDeductionObserved:
          checkList.anyTDSTCSNonDeductionObserved || false,
        tdsTCSNonDeductionDetails: checkList.tdsTCSNonDeductionDetails || "",
        tdsTCSNonDeductionDetailsRemark:
          checkList.tdsTCSNonDeductionDetailsRemark || "",
        anyTDSTCSNonPaymentsObserved:
          checkList.anyTDSTCSNonPaymentsObserved || false,
        tdsTCSNonPaymentsDetails: checkList.tdsTCSNonPaymentsDetails || "",
        anyTDSTCSNonPaymentsObservedRemark:
          checkList.anyTDSTCSNonPaymentsObservedRemark || "",
        anyDisallowanceBasedOnAbove:
          checkList.anyDisallowanceBasedOnAbove || false,
        disallowanceDetails: checkList.disallowanceDetails || "",
        anyDisallowanceBasedOnAboveRemark:
          checkList.anyDisallowanceBasedOnAboveRemark || "",
        checkIncomeAsPer26ASAccounts:
          checkList.checkIncomeAsPer26ASAccounts || false,
        checkIncomeAsPer26ASAccountsRemark:
          checkList.checkIncomeAsPer26ASAccountsRemark || "",
        tdsTCSCreditMatching: checkList.tdsTCSCreditMatching || false,
        tdsTCSCreditMatchingRemark: checkList.tdsTCSCreditMatchingRemark || "",
        additionalReportingAISNotReflectedInAccounts:
          checkList.additionalReportingAISNotReflectedInAccounts || false,
        additionalReportingDetails: checkList.additionalReportingDetails || "",
        additionalReportingAISNotReflectedInAccountsRemark:
          checkList.additionalReportingAISNotReflectedInAccountsRemark || "",
        anyPurchasesDuringYear: checkList.anyPurchasesDuringYear || false,
        invoicesForFixedAssetsVerified:
          checkList.invoicesForFixedAssetsVerified || false,
        anyPurchasesDuringYearRemark:
          checkList.anyPurchasesDuringYearRemark || "",
        checkGSTInputCreditUtilisation:
          checkList.checkGSTInputCreditUtilisation || false,
        checkGSTInputCreditUtilisationRemark:
          checkList.checkGSTInputCreditUtilisationRemark || "",
        anySaleOfFixedAssetsDuringYear:
          checkList.anySaleOfFixedAssetsDuringYear || false,
        invoicesForSaleOfFixedAssetsVerified:
          checkList.invoicesForSaleOfFixedAssetsVerified || false,
        anySaleOfFixedAssetsDuringYearRemark:
          checkList.anySaleOfFixedAssetsDuringYearRemark || "",
        companyVehiclesUsedForPersonalPurposes:
          checkList.companyVehiclesUsedForPersonalPurposes || false,
        disallowanceOnCompanyVehicles:
          checkList.disallowanceOnCompanyVehicles || false,
        companyVehiclesUsedForPersonalPurposesRemark:
          checkList.companyVehiclesUsedForPersonalPurposesRemark || "",
        bankLoansCount: checkList.bankLoansCount || "",
        bankAccountsLoanReceived: checkList.bankAccountsLoanReceived || "",
        statementsCoverage: checkList.statementsCoverage || false,
        statementsCoverageRemark: checkList.statementsCoverageRemark || "",
        newLoansSanctionedDuringPY:
          checkList.newLoansSanctionedDuringPY || false,
        newLoansSanctionedDuringPYRemark:
          checkList.newLoansSanctionedDuringPYRemark || "",
        chargeCreationRequiredWithROC:
          checkList.chargeCreationRequiredWithROC || false,
        chargeCreationRequiredWithROCRemark:
          checkList.chargeCreationRequiredWithROCRemark || "",
        chargeFormsReceivedForVerification:
          checkList.chargeFormsReceivedForVerification || false,
        chargeFormsReceivedForVerificationRemark:
          checkList.chargeFormsReceivedForVerificationRemark || "",
        chargeFormsSRN: checkList.chargeFormsSRN || "",
        bankLoanUtilisationChecked:
          checkList.bankLoanUtilisationChecked || false,
        bankLoanUtilisationCheckedRemark:
          checkList.bankLoanUtilisationCheckedRemark || "",
        otherPurposeUtilisationDetails:
          checkList.otherPurposeUtilisationDetails || false,
        otherPurposeUtilisationDetailsDetais:
          checkList.otherPurposeUtilisationDetailsDetais || "",
        otherPurposeUtilisationDetailsRemark:
          checkList.otherPurposeUtilisationDetailsRemark || "",
        odCcLimitsUtilisedForPersonalOrLongTerm:
          checkList.odCcLimitsUtilisedForPersonalOrLongTerm || false,
        odCcLimitsUtilisationDetails:
          checkList.odCcLimitsUtilisationDetails || "",
        odCcLimitsUtilisedForPersonalOrLongTermRemark:
          checkList.odCcLimitsUtilisedForPersonalOrLongTermRemark || "",
        sundryDebtorsList: checkList.sundryDebtorsList || "",
        outstandingAmountOverSixMonths:
          checkList.outstandingAmountOverSixMonths || "",
        amountOutstandingOverOneYear:
          checkList.amountOutstandingOverOneYear || "",
        verifiedAccountStatementWithDebtors:
          checkList.verifiedAccountStatementWithDebtors || false,
        verifiedAccountStatementWithDebtorsRemark:
          checkList.verifiedAccountStatementWithDebtorsRemark || "",
        sundryCreditorsList: checkList.sundryCreditorsList || "",
        outstandingAmountOverSixMonthsCreditor:
          checkList.outstandingAmountOverSixMonthsCreditor || "",
        amountOutstandingOverOneYearCreditor:
          checkList.amountOutstandingOverOneYearCreditor || "",
        numberOfSMEVendors: checkList.numberOfSMEVendors || "",
        numberOfAccountsOutstandingOver15or45Days:
          checkList.numberOfAccountsOutstandingOver15or45Days || "",
        amountOutstandingOver15or45Days:
          checkList.amountOutstandingOver15or45Days || "",
        disallowanceAmountUnder44BH:
          checkList.disallowanceAmountUnder44BH || "",
        verifiedAccountStatementWithCreditors:
          checkList.verifiedAccountStatementWithCreditors || false,
        verifiedAccountStatementWithCreditorsRemark:
          checkList.verifiedAccountStatementWithCreditorsRemark || "",
        investmentsInEntity: checkList.investmentsInEntity || false,
        investmentsInEntityRemark: checkList.investmentsInEntityRemark || "",
        verifiedInvestmentDetails: checkList.verifiedInvestmentDetails || "",
        investmentIncome: checkList.investmentIncome || "",
        verifyInvestmentBalanceSheets:
          checkList.verifyInvestmentBalanceSheets || false,
        verifyInvestmentBalanceSheetsRemark:
          checkList.verifyInvestmentBalanceSheetsRemark || "",
        obtainBrokerContractNotes: checkList.obtainBrokerContractNotes || false,
        obtainBrokerContractNotesRemark:
          checkList.obtainBrokerContractNotesRemark || "",
        obtainCapitalGainLossComputation:
          checkList.obtainCapitalGainLossComputation || false,
        obtainCapitalGainLossComputationRemark:
          checkList.obtainCapitalGainLossComputationRemark || "",
        complianceWithCompaniesAct:
          checkList.complianceWithCompaniesAct || false,
        complianceWithCompaniesActRemark:
          checkList.complianceWithCompaniesActRemark || "",
        isCAROApplicable: checkList.isCAROApplicable || false,
        reasonForApplicability: checkList.reasonForApplicability || "",
        isCAROApplicableRemark: checkList.isCAROApplicableRemark || "",
        apportionmentOfPrincipalAndInterest:
          checkList.apportionmentOfPrincipalAndInterest || false,
        apportionmentOfPrincipalAndInterestRemake:
          checkList.apportionmentOfPrincipalAndInterestRemake || "",
        tdsDeductedOnInterestPayment:
          checkList.tdsDeductedOnInterestPayment || false,
        tdsDeductedOnInterestPaymentRemake:
          checkList.tdsDeductedOnInterestPaymentRemake || "",
        properEntryInBooksOfAccounts:
          checkList.properEntryInBooksOfAccounts || false,
        properEntryInBooksOfAccountsRemake:
          checkList.properEntryInBooksOfAccountsRemake || "",
        computationChecked: checkList.computationChecked || false,
        computationCheckedRemark: checkList.computationCheckedRemark || "",
        loanGrantedToRelatedParty: checkList.loanGrantedToRelatedParty || false,
        loanGrantedToRelatedPartyRemark:
          checkList.loanGrantedToRelatedPartyRemark || "",
        loanAndAdvanceAuthorization:
          checkList.loanAndAdvanceAuthorization || false,
        loanAndAdvanceAuthorizationRemark:
          checkList.loanAndAdvanceAuthorizationRemark || "",
        cashReceiptsAndPaymentsToLoanAccount:
          checkList.cashReceiptsAndPaymentsToLoanAccount || false,
        cashReceiptsAndPaymentsToLoanAccountRemark:
          checkList.cashReceiptsAndPaymentsToLoanAccountRemark || "",
        securityAgreement: checkList.securityAgreement || false,
        securityAgreementRemark: checkList.securityAgreementRemark || "",
        securityRefundable: checkList.securityRefundable || false,
        securityRefundableRemark: checkList.securityRefundableRemark || "",
        securityReturnAgreement: checkList.securityReturnAgreement || false,
        securityReturnAgreementRemark:
          checkList.securityReturnAgreementRemark || "",
        obtainSignedCashBalanceCertificate:
          checkList.obtainSignedCashBalanceCertificate || false,
        obtainSignedCashBalanceCertificateRemark:
          checkList.obtainSignedCashBalanceCertificateRemark || "",
        receivedClosingStockConfirmation:
          checkList.receivedClosingStockConfirmation || false,
        receivedClosingStockConfirmationRemark:
          checkList.receivedClosingStockConfirmationRemark || "",
        receivedStockLossConfirmation:
          checkList.receivedStockLossConfirmation || false,
        receivedStockLossConfirmationRemark:
          checkList.receivedStockLossConfirmationRemark || "",
        revenueFromOperations: checkList.revenueFromOperations || "",
        copyOfInvoices: checkList.copyOfInvoices || "",
        purchaseAndInvoiceDetails: checkList.purchaseAndInvoiceDetails || "",
        tdsApplicabilitySalary: checkList.tdsApplicabilitySalary || false,
        tdsApplicabilitySalaryRemark:
          checkList.tdsApplicabilitySalaryRemark || "",
        tdsObservations: checkList.tdsObservations || "",
        totalEmployeesForESIEFI: checkList.totalEmployeesForESIEFI || "",
        esiCoveredEmployees: checkList.esiCoveredEmployees || "",
        registeredUnderESI: checkList.registeredUnderESI || false,
        registeredUnderEPF: checkList.registeredUnderEPF || false,
        esiObservationsShort: checkList.esiObservationsShort || "",
        esiObservationsNon: checkList.esiObservationsNon || "",
        epfObservationsShort: checkList.epfObservationsShort || "",
        epfObservationsNon: checkList.epfObservationsNon || "",
        disallowances: checkList.disallowances || "",
        gstReverseChargeExpenses: checkList.gstReverseChargeExpenses || "",
        personalCharityClubExpenses:
          checkList.personalCharityClubExpenses || "",
        regularlyOccurringExpenses: checkList.regularlyOccurringExpenses || "",
        yearEndExpenseEntries: checkList.yearEndExpenseEntries || "",
        capitalNatureExpenses: checkList.capitalNatureExpenses || "",
        gstInputClaim: checkList.gstInputClaim || "",
        gstReturnGSTR: checkList.gstReturnGSTR || "",
        gstReturnComparison: checkList.gstReturnComparison || "",
        changesOwnershipDuringPY: checkList.changesOwnershipDuringPY || false,
        changesOwnershipDuringPYRemark:
          checkList.changesOwnershipDuringPYRemark || "",
        detailsChangesOwnership: checkList.detailsChangesOwnership || "",
        documentsVerificationChangesOwnership:
          checkList.documentsVerificationChangesOwnership || false,
        documentsVerificationChangesOwnershipRemark:
          checkList.documentsVerificationChangesOwnershipRemark || "",
          anyShareTransferAuditPeriod: checkList.anyShareTransferAuditPeriod || false,
          anyShareTransferAuditPeriodRemark: checkList.anyShareTransferAuditPeriodRemark || "",
          sh4Received: checkList.sh4Received || false,
          sh4ReceivedRemark: checkList.sh4ReceivedRemark || "",
          noOfShareTransfer: checkList.noOfShareTransfer || "",
          nameTransferer:checkList.nameTransferer || "",
          nameTransferee: checkList.nameTransferee || "",
          changeInDirector: checkList.changeInDirector || false,
          changeInDirectorRemark: checkList.changeInDirectorRemark || "",
          dpt3applicable: checkList.dpt3applicable || false,
          dpt3applicableLoan: checkList.dpt3applicableLoan || "",

        managementChanges: checkList.managementChanges || false,
        managementChangesRemark: checkList.managementChangesRemark || "",
        detailsManagementChanges: checkList.detailsManagementChanges || "",
        verificationDocumentsManagement:
          checkList.verificationDocumentsManagement || false,
        verificationDocumentsManagementRemark:
          checkList.verificationDocumentsManagementRemark || "",
        receivedGrantsSubsidies: checkList.receivedGrantsSubsidies || false,
        receivedGrantsSubsidiesRemark:
          checkList.receivedGrantsSubsidiesRemark || "",
        natureGrantsSubsidies: checkList.natureGrantsSubsidies || "",
        refundable: checkList.refundable || false,
        refundableRemark: checkList.refundableRemark || "",
        accountingTreatment: checkList.accountingTreatment || "",
        conditions: checkList.conditions || "",
        complianceConditions: checkList.complianceConditions || "",
        accountingTreatmentGrants: checkList.accountingTreatmentGrants || "",
        pendingDisputes: checkList.pendingDisputes || false,
        pendingDisputesRemark: checkList.pendingDisputesRemark || "",
        detailedVerification: checkList.detailedVerification || "",
        obsRemarks_100: checkList.obsRemarks_100 || "",
      }));
    }
  }, [checkList]);

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row className="pt-5">
          <Col>
            <h2 className=" text-center">UPDATE CHECKLIST</h2>
          </Col>
          </Row>
          <Row className="py-4 d-flex justify-content-end">
          <Col md={3} className="d-flex justify-content-around">
            <Button
              variant="success"
              onClick={() => {
                navigate(`/view/${checkListId}`);
              }}
            >
              View checklist
            </Button>
            <Button
            className="update_btn"
            style={{minWidth:"125px"}}
              variant="danger"
              type="submit"
            >
              Update
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="orderDate">
            <Form.Label>
              Order Date : {formattedDate(formData.orderDate)}
            </Form.Label>
            <Form.Control
              type="date"
              name="orderDate"
              value={formData.orderDate || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="targetDate">
            <Form.Label>
              Target Date : {formattedDate(formData.targetDate)}
            </Form.Label>
            <Form.Control
              type="date"
              name="targetDate"
              value={formData.targetDate || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} controlId="businessName">
              <Form.Label>BUSINESS NAME</Form.Label>
              <Form.Control
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="businessFormat">
              <Form.Label>BUSINESS FORMAT</Form.Label>
              <Form.Control
                type="text"
                name="businessFormat"
                value={formData.businessFormat}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="placeOfBusiness">
              <Form.Label>PLACE OF BUSINESS</Form.Label>
              <Form.Control
                type="text"
                name="placeOfBusiness"
                value={formData.placeOfBusiness}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="state">
              <Form.Label>STATE</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="businessActivity">
              <Form.Label>BUSINESS ACTIVITY</Form.Label>
              <Form.Control
                type="text"
                name="businessActivity"
                value={formData.businessActivity}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          {/* //changes  */}
          <Row className="mb-3">
            <Form.Group as={Col} controlId="doi">
              <Form.Label>DATE OF INCORPORATION -  {formattedDate(formData.doi)}</Form.Label>
              <Form.Control
                type="date"
                name="doi"
                value={formData.doi || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="yoa_100">
              <Form.Label>YEAR OF AUDIT</Form.Label>
              <Form.Control
                type="text"
                name="yoa_100"
                value={formData.yoa_100}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="yoa">
              <Form.Label>FIRST / SUBSEQUENT</Form.Label>
              <Form.Control
                as="select"
                name="yoa"
                value={formData.yoa || ""}
                onChange={handleChange}
                required
              >
                <option value="First Year">First Year</option>
                <option value="Subsequent Years">Subsequent Years</option>
                <option value="No audit">No audit</option>
                <option value="NA">NA</option>
              </Form.Control>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="auditor.isThisYearsAuditorTheSame">
              <Form.Label>
                IS THIS YEAR'S AUDITOR THE SAME AS LAST YEAR'S?
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="isThisYearsAuditorTheSame"
                checked={formData.isThisYearsAuditorTheSame}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="isThisYearsAuditorTheSameRemarks">
              <Form.Label>Add remarks...</Form.Label>
              <Form.Control
                type="text"
                name="isThisYearsAuditorTheSameRemarks"
                value={formData.isThisYearsAuditorTheSameRemarks}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          {formData.isThisYearsAuditorTheSame && (
            <>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="auditor.currentYearAuditorPeriod"
                >
                  <Form.Label>
                    PERIOD OF APPOINTMENT FOR THE CURRENT AUDITOR?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="currentYearAuditorPeriod"
                    value={formData.currentYearAuditorPeriod}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="auditor.currentYearAuditorRenewalDue"
                >
                  <Form.Label>IS RENEWAL OF APPOINTMENT DUE?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    name="currentYearAuditorRenewalDue"
                    checked={formData.currentYearAuditorRenewalDue}
                    onChange={handleChange}
                  />
                </Form.Group>

                {formData.currentYearAuditorRenewalDue && (
                 <Form.Group
                 as={Col}
                 controlId="auditor.currentYearAuditorRenewalSRN"
               >
                 <Form.Label>SRN OF FORM ADT 1 FILED.</Form.Label>
                 <Form.Check
                   type="checkbox"
                   label="Yes"
                   name="currentYearAuditorRenewalSRN"
                   checked={formData.currentYearAuditorRenewalSRN}
                   onChange={handleChange}
                 />
               </Form.Group>
                )}
              </Row>
            </>
          )}
          <Row className="mb-3">
            {!formData.isThisYearsAuditorTheSame && (
              <Form.Group as={Col} controlId="auditor.ifNoPreviousAuditor">
                <Form.Label>
                  IF NO, THEN WHO IS THE PREVIOUS AUDITOR?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="ifNoPreviousAuditor"
                  value={formData.ifNoPreviousAuditor}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Row>

          {!formData.isThisYearsAuditorTheSame && (
            <>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="auditor.currentYearAuditor">
                  <Form.Label>WHO IS THE CURRENT YEAR AUDITOR?</Form.Label>
                  <Form.Control
                    type="text"
                    name="currentYearAuditor"
                    value={formData.currentYearAuditor}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="filedByPreAuditor">
                  <Form.Label>
                    WHETHER THE FORM ADT 3 HAS BEEN FILED BY THE PRE AUDITOR?
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    name="filedByPreAuditor"
                    checked={formData.filedByPreAuditor}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="filedByPreAuditorRemark">
                  <Form.Label>Add remarks...</Form.Label>
                  <Form.Control
                    type="text"
                    name="filedByPreAuditorRemark"
                    value={formData.filedByPreAuditorRemark}
                    onChange={handleChange}
                  />
                </Form.Group>
                {formData.filedByPreAuditor && (
                  <Form.Group as={Col} controlId="srnNumberADT3">
                    <Form.Label>IF YES, PLEASE MENTION SRN NUMBER</Form.Label>
                    <Form.Control
                      type="text"
                      name="srnNumberADT3"
                      value={formData.srnNumberADT3}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formADT3Verified">
                  <Form.Label>
                    WHETHER THE FORM ADT 3 HAS BEEN VERIFIED?
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    name="formADT3Verified"
                    checked={formData.formADT3Verified}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formADT3VerifiedRemark">
                  <Form.Label>Add remarks...</Form.Label>
                  <Form.Control
                    type="text"
                    name="formADT3VerifiedRemarks"
                    value={formData.formADT3VerifiedRemarks}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="nocReceivedFromPreAuditor">
                  <Form.Label>
                    WHETHER THE NOC FROM PRE AUDITOR HAS BEEN RECEIVED?
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    name="nocReceivedFromPreAuditor"
                    checked={formData.nocReceivedFromPreAuditor}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="nocReceivedFromPreAuditorRemark"
                >
                  <Form.Label>Add remarks...</Form.Label>
                  <Form.Control
                    type="text"
                    name="nocReceivedFromPreAuditorRemark"
                    value={formData.nocReceivedFromPreAuditorRemark}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formADT1Filed">
                  <Form.Label>
                    WHETHER THE FORM ADT 1 FILED BY THE COMPANY FOR APPOINTMENT
                    OF NEW AUDITOR?
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    name="formADT1Filed"
                    checked={formData.formADT1Filed}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formADT1FiledRemark">
                  <Form.Label>Add remarks...</Form.Label>
                  <Form.Control
                    type="text"
                    name="formADT1FiledRemark"
                    value={formData.formADT1FiledRemark}
                    onChange={handleChange}
                  />
                </Form.Group>
                {formData.formADT1Filed && (
                  <Form.Group as={Col} controlId="srnNumberADT1">
                    <Form.Label>IF YES, PLEASE MENTION SRN NUMBER</Form.Label>
                    <Form.Control
                      type="text"
                      name="srnNumberADT1"
                      value={formData.srnNumberADT1}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formADT1Verified">
                  <Form.Label>
                    WHETHER THE FORM ADT 1 HAS BEEN VERIFIED?
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Yes"
                    name="formADT1Verified"
                    checked={formData.formADT1Verified}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formADT1VerifiedRemark">
                  <Form.Label>Add remarks...</Form.Label>
                  <Form.Control
                    type="text"
                    name="formADT1VerifiedRemark"
                    value={formData.formADT1VerifiedRemark}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            </>
          )}
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formAOC4Filed">
              <Form.Label>
                WHETHER THE FORM AOC 4 FOR THE PRE YEAR HAS BEEN FILED?
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="formAOC4Filed"
                checked={formData.formAOC4Filed}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formAOC4FiledRemark">
              <Form.Label>Add remarks...</Form.Label>
              <Form.Control
                type="text"
                name="formAOC4FiledRemark"
                value={formData.formAOC4FiledRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formMGT7AFiled">
              <Form.Label>
                WHETHER THE FORM MGT 7A FOR THE PRE YEAR HAS BEEN FILED?
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="formMGT7AFiled"
                checked={formData.formMGT7AFiled}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formMGT7AFiledRemark">
              <Form.Label>Add remarks...</Form.Label>
              <Form.Control
                type="text"
                name="formMGT7AFiledRemark"
                value={formData.formMGT7AFiledRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="pyAuditedFinancialsGSTVerified">
              <Form.Label>
                WHETHER PY AUDITED FINANCIALS & GST AR VERIFIED?
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="pyAuditedFinancialsGSTVerified"
                checked={formData.pyAuditedFinancialsGSTVerified}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="pyAuditedFinancialsGSTVerifiedRemark"
            >
              <Form.Label>Add remarks...</Form.Label>
              <Form.Control
                type="text"
                name="pyAuditedFinancialsGSTVerifiedRemark"
                value={formData.pyAuditedFinancialsGSTVerifiedRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formINC20AFiled">
              <Form.Label>WHETHER THE FORM INC 20A IS FILED?</Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="formINC20AFiled"
                checked={formData.formINC20AFiled}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formINC20AFiledRemark">
              <Form.Label>Add remarks...</Form.Label>
              <Form.Control
                type="text"
                name="formINC20AFiledRemark"
                value={formData.formINC20AFiledRemark}
                onChange={handleChange}
              />
            </Form.Group>
            {formData.formINC20AFiled && (
              <Form.Group as={Col} controlId="formINC20AFiledSRN">
                <Form.Label>IF YES, PLEASE MENTION SRN NUMBER</Form.Label>
                <Form.Control
                  type="text"
                  name="formINC20AFiledSRN"
                  value={formData.formINC20AFiledSRN}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="numberOfBankAccounts">
            <Form.Label>
              HOW MANY BANK ACCOUNTS DOES THE COMPANY HAVE?
            </Form.Label>
            <Form.Control
              type="number"
              name="numberOfBankAccounts"
              value={formData.numberOfBankAccounts}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="numberOfBankAccountsReceived">
            <Form.Label>HOW MANY BANK ACCOUNTS RECEIVED?</Form.Label>
            <Form.Control
              type="number"
              name="numberOfBankAccountsReceived"
              value={formData.numberOfBankAccountsReceived}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="statementsCoverWholeYear">
            <Form.Label>
              DO THE STATEMENTS COVER EITHER THE WHOLE YEAR OR, IF OPENED LATER,
              FROM THE OPENING DATE TO THE YEAR END?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="statementsCoverWholeYear"
              checked={formData.statementsCoverWholeYear}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="statementsCoverWholeYearRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="statementsCoverWholeYearRemark"
              value={formData.statementsCoverWholeYearRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="openingBalancesMatchingPY">
            <Form.Label>
              DOES THE OPENING BALANCES ARE MATCHING WITH THE PY CLOSING
              FIGURES?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="openingBalancesMatchingPY"
              checked={formData.openingBalancesMatchingPY}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="openingBalancesMatchingPYRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="openingBalancesMatchingPYRemark"
              value={formData.openingBalancesMatchingPYRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        {/* // GST Login */}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="hasRegisteredUnderGST">
            <Form.Label>HAVE REGISTERED UNDER GST?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="hasRegisteredUnderGST"
              checked={formData.hasRegisteredUnderGST}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="hasRegisteredUnderGSTRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="hasRegisteredUnderGSTRemark"
              value={formData.hasRegisteredUnderGSTRemark}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.hasRegisteredUnderGST && (
            <>
              <Form.Group as={Col} controlId="gstUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="gstLoginCredentialsUsername"
                  value={formData.gstLoginCredentialsUsername}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="gstPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="gstLoginCredentialsPassword"
                  value={formData.gstLoginCredentialsPassword}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
        </Row>
        {/* // income tax  */}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="hasRegisteredUnderIncomeTax">
            <Form.Label>HAVE REGISTERED UNDER INCOME TAX?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="hasRegisteredUnderIncomeTax"
              checked={formData.hasRegisteredUnderIncomeTax}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="hasRegisteredUnderIncomeTaxRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="hasRegisteredUnderIncomeTaxRemark"
              value={formData.hasRegisteredUnderIncomeTaxRemark}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.hasRegisteredUnderIncomeTax && (
            <>
              <Form.Group as={Col} controlId="incomeTaxUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="incomeTaxLoginCredentialsUsername"
                  value={formData.incomeTaxLoginCredentialsUsername}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="incomeTaxPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="incomeTaxLoginCredentialsPassword"
                  value={formData.incomeTaxLoginCredentialsPassword}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
        </Row>
        {/* // ESI  */}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="hasRegisteredUnderESI">
            <Form.Label>HAVE REGISTERED UNDER ESI?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="hasRegisteredUnderESI"
              checked={formData.hasRegisteredUnderESI}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="hasRegisteredUnderESIRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="hasRegisteredUnderESIRemark"
              value={formData.hasRegisteredUnderESIRemark}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.hasRegisteredUnderESI && (
            <>
              <Form.Group as={Col} controlId="esiUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="esiLoginCredentialsUsername"
                  value={formData.esiLoginCredentialsUsername}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="esiPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="esiLoginCredentialsPassword"
                  value={formData.esiLoginCredentialsPassword}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
        </Row>
        {/* // PF */}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="hasRegisteredUnderPF">
            <Form.Label>HAVE REGISTERED UNDER PF?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="hasRegisteredUnderPF"
              checked={formData.hasRegisteredUnderPF}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="hasRegisteredUnderPFRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="hasRegisteredUnderPFRemark"
              value={formData.hasRegisteredUnderPFRemark}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.hasRegisteredUnderPF && (
            <>
              <Form.Group as={Col} controlId="pfUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="pfLoginCredentialsUsername"
                  value={formData.pfLoginCredentialsUsername}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="pfPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="pfLoginCredentialsPassword"
                  value={formData.pfLoginCredentialsPassword}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
        </Row>
        {/* // Under traces */}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="hasRegisteredUnderTRACES">
            <Form.Label>HAVE REGISTERED UNDER TRACES?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="hasRegisteredUnderTRACES"
              checked={formData.hasRegisteredUnderTRACES}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="hasRegisteredUnderTRACESRemark">
            <Form.Label>Add remarks...</Form.Label>
            <Form.Control
              type="text"
              name="hasRegisteredUnderTRACESRemark"
              value={formData.hasRegisteredUnderTRACESRemark}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.hasRegisteredUnderTRACES && (
            <>
              <Form.Group as={Col} controlId="tracesUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="tracesLoginCredentialsUsername"
                  value={formData.tracesLoginCredentialsUsername}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="tracesPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="tracesLoginCredentialsPassword"
                  value={formData.tracesLoginCredentialsPassword}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
        </Row>
        {/* // GOODS AND SERVICE TAX  */}
        <h6 className="mt-5">Good and Services tax</h6>
        <hr className="mb-5" />
        <Form.Group as={Row} controlId="goodsAndServicesDealing">
          <Form.Label column sm={4}>
            Goods and Services Dealing with & Applicable GST Rates
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="goodsAndServicesDealing"
              value={formData.goodsAndServicesDealing}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="salesTurnoverReconciliation">
          <Form.Label column sm={4}>
            Sales Turnover Reconciliation
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="salesTurnoverReconciliation"
              value={formData.salesTurnoverReconciliation}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="purchaseTurnoverReconciliation">
          <Form.Label column sm={4}>
            Purchase Turnover Reconciliation
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="purchaseTurnoverReconciliation"
              value={formData.purchaseTurnoverReconciliation}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="gstInputOutputReconciliation">
          <Form.Label column sm={4}>
            GST Input & Output Reconciliation
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="gstInputOutputReconciliation"
              value={formData.gstInputOutputReconciliation}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Row className="my-3">
          <Col md={7}>
            <Form.Group as={Row} controlId="anyExportImportProceeds">
              <Form.Label column md={7}>
                Any Export/Import Proceeds?
              </Form.Label>
              <Col md={5}>
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  name="anyExportImportProceeds"
                  checked={formData.anyExportImportProceeds}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group controlId="anyExportImportProceedsRemark">
              <Form.Control
                placeholder="Add remarks.."
                type="text"
                name="anyExportImportProceedsRemark"
                value={formData.anyExportImportProceedsRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={7}>
            <Form.Group as={Row} controlId="haveAnyExportsTurnover">
              <Form.Label column md={7}>
                Have any Exports Turnover?
              </Form.Label>
              <Col md={5}>
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  name="haveAnyExportsTurnover"
                  checked={formData.haveAnyExportsTurnover}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group controlId="haveAnyExportsTurnoverRemark">
              <Form.Control
                placeholder="Add remarks.."
                type="text"
                name="haveAnyExportsTurnoverRemark"
                value={formData.haveAnyExportsTurnoverRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={7}>
            <Form.Group as={Row} controlId="haveAppliedForLUT">
              <Form.Label column md={7}>
                Have Applied for LUT in GST Portal?
              </Form.Label>
              <Col md={5}>
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  name="haveAppliedForLUT"
                  checked={formData.haveAppliedForLUT}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group controlId="haveAppliedForLUTRemark">
              <Form.Control
                placeholder="Add remarks.."
                type="text"
                name="haveAppliedForLUTRemark"
                value={formData.haveAppliedForLUTRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group as={Row} controlId="copyOfLUTToBeObtained">
          <Form.Label column sm={4}>
            Copy of LUT to be obtained for the year under review
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="copyOfLUTToBeObtained"
              value={formData.copyOfLUTToBeObtained}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="delayedPaymentsToCreditors">
          <Form.Label column sm={4}>
            Delayed Payments to Creditors/Vendors over 180 Days
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="delayedPaymentsToCreditors"
              value={formData.delayedPaymentsToCreditors}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="gstInputOnDelayedTransactions">
          <Form.Label column sm={4}>
            GST Input on Such Delayed Transactions
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="gstInputOnDelayedTransactions"
              value={formData.gstInputOnDelayedTransactions}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        {/* // INCOME TAX  */}
        <h6 className="mt-5">INCOME TAX</h6>
        <hr className="mb-5" />
        <Row>
          <Form.Group as={Col} controlId="anyTDSDeductionsDuringYear">
            <Form.Label>ANY TDS DEDUCTIONS DURING THE YEAR?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anyTDSDeductionsDuringYear"
              checked={formData.anyTDSDeductionsDuringYear}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="anyTDSDeductionsDuringYearRemark">
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="anyTDSDeductionsDuringYearRemark"
              value={formData.anyTDSDeductionsDuringYearRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="preparedTDSTCSReconciliation">
            <Form.Label>PREPARED TDS&TCS RECONCILIATION?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="preparedTDSTCSReconciliation"
              checked={formData.preparedTDSTCSReconciliation}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="preparedTDSTCSReconciliationRemark">
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="preparedTDSTCSReconciliationRemark"
              value={formData.preparedTDSTCSReconciliationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="anyTDSTCSLowerDeductionObserved">
            <Form.Label>ANY TDS&TCS LOWER DEDUCTION OBSERVED?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anyTDSTCSLowerDeductionObserved"
              checked={formData.anyTDSTCSLowerDeductionObserved}
              onChange={handleChange}
            />
          </Form.Group>

          {formData.anyTDSTCSLowerDeductionObserved && (
            <>
              <Form.Group as={Col} controlId="tdsTCSDetailsLowerDeduction">
                <Form.Label>
                  IF YES, GIVE DETAILS OF TDS&TCS LOWER DEDUCTION
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter details"
                  name="tdsTCSDetailsLowerDeduction"
                  value={formData.tdsTCSDetailsLowerDeduction}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}

          <Form.Group
            as={Col}
            controlId="anyTDSTCSLowerDeductionObservedRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="anyTDSTCSLowerDeductionObservedRemark"
              value={formData.anyTDSTCSLowerDeductionObservedRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="anyTDSTCSNonDeductionObserved">
            <Form.Label>ANY TDS&TCS NON DEDUCTION OBSERVED?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anyTDSTCSNonDeductionObserved"
              checked={formData.anyTDSTCSNonDeductionObserved}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.anyTDSTCSNonDeductionObserved && (
            <>
              <Form.Group as={Col} controlId="tdsTCSNonDeductionDetails">
                <Form.Label>
                  IF YES, GIVE DETAILS OF TDS&TCS NON DEDUCTION OBSERVED
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter details"
                  name="tdsTCSNonDeductionDetails"
                  value={formData.tdsTCSNonDeductionDetails}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
          <Form.Group as={Col} controlId="tdsTCSNonDeductionDetailsRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="tdsTCSNonDeductionDetailsRemark"
              value={formData.tdsTCSNonDeductionDetailsRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="anyTDSTCSNonPaymentsObserved">
            <Form.Label>ANY TDS&TCS NON PAYMENTS OBSERVED?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anyTDSTCSNonPaymentsObserved"
              checked={formData.anyTDSTCSNonPaymentsObserved}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.anyTDSTCSNonPaymentsObserved && (
            <>
              <Form.Group as={Col} controlId="tdsTCSNonPaymentsDetails">
                <Form.Label>
                  IF YES, GIVE DETAILS OF TDS&TCS NON PAYMENTS OBSERVED
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter details"
                  name="tdsTCSNonPaymentsDetails"
                  value={formData.tdsTCSNonPaymentsDetails}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
          <Form.Group as={Col} controlId="anyTDSTCSNonPaymentsObservedRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="anyTDSTCSNonPaymentsObservedRemark"
              value={formData.anyTDSTCSNonPaymentsObservedRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="anyDisallowanceBasedOnAbove">
            <Form.Label>ANY DISALLOWANCE BASED ON THE ABOVE?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anyDisallowanceBasedOnAbove"
              checked={formData.anyDisallowanceBasedOnAbove}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.anyDisallowanceBasedOnAbove && (
            <>
              <Form.Group as={Col} controlId="disallowanceDetails">
                <Form.Label>IF YES, GIVE DETAILS OF DISALLOWANCE</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter details"
                  name="disallowanceDetails"
                  value={formData.disallowanceDetails}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}
          <Form.Group as={Col} controlId="anyDisallowanceBasedOnAboveRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="anyDisallowanceBasedOnAboveRemark"
              value={formData.anyDisallowanceBasedOnAboveRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="checkIncomeAsPer26ASAccounts">
            <Form.Label>CHECK INCOME AS PER 26 AS & ACCOUNTS?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="checkIncomeAsPer26ASAccounts"
              checked={formData.checkIncomeAsPer26ASAccounts}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="checkIncomeAsPer26ASAccountsRemark">
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="checkIncomeAsPer26ASAccountsRemark"
              value={formData.checkIncomeAsPer26ASAccountsRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="tdsTCSCreditMatching">
            <Form.Label>
              TDS&TCS CREDIT AS PER 26 AS & ACCOUNTS ARE MATCHING?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="tdsTCSCreditMatching"
              checked={formData.tdsTCSCreditMatching}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="tdsTCSCreditMatchingRemark">
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="tdsTCSCreditMatchingRemark"
              value={formData.tdsTCSCreditMatchingRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="additionalReportingAISNotReflectedInAccounts"
          >
            <Form.Label>
              ANY ADDITIONAL REPORTING IN AIS NOT REFLECTED IN ACCOUNTS?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="additionalReportingAISNotReflectedInAccounts"
              checked={formData.additionalReportingAISNotReflectedInAccounts}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.additionalReportingAISNotReflectedInAccounts && (
            <>
              <Form.Group as={Col} controlId="additionalReportingDetails">
                <Form.Label>
                  IF YES, GIVE DETAILS OF ADDITIONAL REPORTING
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter details"
                  name="additionalReportingDetails"
                  value={formData.additionalReportingDetails}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
          )}

          <Form.Group
            as={Col}
            controlId="additionalReportingAISNotReflectedInAccountsRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="additionalReportingAISNotReflectedInAccountsRemark"
              value={
                formData.additionalReportingAISNotReflectedInAccountsRemark
              }
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* // Fixed Assets  */}
        <h6 className="mt-5">Fixed Assets </h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="anyPurchasesDuringYear">
            <Form.Label>ANY PURCHASES DURING THE YEAR?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anyPurchasesDuringYear"
              checked={formData.anyPurchasesDuringYear}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.anyPurchasesDuringYear && (
            <Form.Group as={Col} controlId="invoicesForFixedAssetsVerified">
              <Form.Label>
                IF YES, INVOICES FOR FIXED ASSETS ARE VERIFIED?
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="invoicesForFixedAssetsVerified"
                checked={formData.invoicesForFixedAssetsVerified}
                onChange={handleChange}
              />
            </Form.Group>
          )}

          <Form.Group as={Col} controlId="anyPurchasesDuringYearRemark">
            <Form.Label> </Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="anyPurchasesDuringYearRemark"
              value={formData.anyPurchasesDuringYearRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="checkGSTInputCreditUtilisation">
            <Form.Label>CHECK GST INPUT CREDIT UTILISATION</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="checkGSTInputCreditUtilisation"
              checked={formData.checkGSTInputCreditUtilisation}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="checkGSTInputCreditUtilisationRemark">
            <Form.Label> </Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="checkGSTInputCreditUtilisationRemark"
              value={formData.checkGSTInputCreditUtilisationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="anySaleOfFixedAssetsDuringYear">
            <Form.Label>ANY SALE OF FIXED ASSETS DURING THE YEAR?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="anySaleOfFixedAssetsDuringYear"
              checked={formData.anySaleOfFixedAssetsDuringYear}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.anySaleOfFixedAssetsDuringYear && (
            <Form.Group
              as={Col}
              controlId="invoicesForSaleOfFixedAssetsVerified"
            >
              <Form.Label>
                IF YES, INVOICES FOR FIXED ASSETS ARE VERIFIED?
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="invoicesForSaleOfFixedAssetsVerified"
                checked={formData.invoicesForSaleOfFixedAssetsVerified}
                onChange={handleChange}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="anySaleOfFixedAssetsDuringYearRemark">
            <Form.Label> </Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="anySaleOfFixedAssetsDuringYearRemark"
              value={formData.anySaleOfFixedAssetsDuringYearRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="companyVehiclesUsedForPersonalPurposes"
          >
            <Form.Label>
              ANY COMPANY VEHICLES USED FOR PERSONAL PURPOSES?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="companyVehiclesUsedForPersonalPurposes"
              checked={formData.companyVehiclesUsedForPersonalPurposes}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.companyVehiclesUsedForPersonalPurposes && (
            <Form.Group as={Col} controlId="disallowanceOnCompanyVehicles">
              <Form.Label>WHAT IS THE DISALLOWANCE ON THAT?</Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="disallowanceOnCompanyVehicles"
                checked={formData.disallowanceOnCompanyVehicles}
                onChange={handleChange}
              />
            </Form.Group>
          )}

          <Form.Group
            as={Col}
            controlId="companyVehiclesUsedForPersonalPurposesRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="companyVehiclesUsedForPersonalPurposesRemark"
              value={formData.companyVehiclesUsedForPersonalPurposesRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* // BANK LOANS */}
        <h6 className="mt-5">BANK LOANS</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="numberOfBankLoanAccounts">
            <Form.Label>HOW MANY BANK LOANS DOES THE COMPANY HAVE?</Form.Label>
            <Form.Control
              type="text"
              name="numberOfBankLoanAccounts"
              checked={formData.numberOfBankLoanAccounts}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="bankAccountsLoanReceived">
            <Form.Label>HOW MANY BANK ACCOUNTS RECEIVED?</Form.Label>
            <Form.Control
              type="text"
              name="bankAccountsLoanReceived"
              checked={formData.bankAccountsLoanReceived}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="statementsCoverage">
            <Form.Label>
              DO THE STATEMENTS COVER EITHER THE WHOLE YEAR OR, IF LOAN AVAILED
              LATER, FROM THE OPENING DATE TO THE YEAR END?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="statementsCoverage"
              checked={formData.statementsCoverage}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="statementsCoverageRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="statementsCoverageRemark"
              value={formData.statementsCoverageRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="newLoansSanctionedDuringPY">
            <Form.Label>ANY NEW LOANS SANCTIONED DURING THE PY?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="newLoansSanctionedDuringPY"
              checked={formData.newLoansSanctionedDuringPY}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="newLoansSanctionedDuringPYRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="newLoansSanctionedDuringPYRemark"
              value={formData.newLoansSanctionedDuringPYRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="chargeCreationRequiredWithROC">
            <Form.Label>IS IT REQUIRED TO CREATE CHARGE WITH ROC?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="chargeCreationRequiredWithROC"
              checked={formData.chargeCreationRequiredWithROC}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="chargeCreationRequiredWithROCRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="chargeCreationRequiredWithROCRemark"
              value={formData.chargeCreationRequiredWithROCRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="chargeFormsReceivedForVerification">
            <Form.Label>CHARGE FORMS RECEIVED FOR VERIFICATION?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="chargeFormsReceivedForVerification"
              checked={formData.chargeFormsReceivedForVerification}
              onChange={handleChange}
            />
          </Form.Group>

          {formData.chargeFormsReceivedForVerification && (
            <Form.Group as={Col} controlId="chargeFormsSRN">
              <Form.Label>IF YES ENTER THE SRN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter SRN"
                name="chargeFormsSRN"
                value={formData.chargeFormsSRN}
                onChange={handleChange}
              />
            </Form.Group>
          )}
          <Form.Group
            as={Col}
            controlId="chargeFormsReceivedForVerificationRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="chargeFormsReceivedForVerificationRemark"
              value={formData.chargeFormsReceivedForVerificationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="bankLoanUtilisationChecked">
            <Form.Label>CHECK BANK LOAN UTILISATION</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="bankLoanUtilisationChecked"
              checked={formData.bankLoanUtilisationChecked}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="bankLoanUtilisationCheckedRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="bankLoanUtilisationCheckedRemark"
              value={formData.bankLoanUtilisationCheckedRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="otherPurposeUtilisationDetails">
            <Form.Label>
              ANY UTILISATION FOR OTHER THAN THE PURPOSE FOR WHICH THE LOAN
              SANCTIONED?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="otherPurposeUtilisationDetails"
              checked={formData.otherPurposeUtilisationDetails}
              onChange={handleChange}
            />
          </Form.Group>

          {formData.otherPurposeUtilisationDetails && (
            <Form.Group
              as={Col}
              controlId="otherPurposeUtilisationDetailsDetais"
            >
              <Form.Label>IF YES, GIVE DETAILS</Form.Label>
              <Form.Control
                type="text"
                name="otherPurposeUtilisationDetailsDetais"
                value={formData.otherPurposeUtilisationDetailsDetais}
                onChange={handleChange}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="otherPurposeUtilisationDetailsRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="otherPurposeUtilisationDetailsRemark"
              value={formData.otherPurposeUtilisationDetailsRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="odCcLimitsUtilisedForPersonalOrLongTerm"
          >
            <Form.Label>
              ANY OD/CC LIMITES SANCTIONED UTILISED FOR PERSONAL / LONG TERM
              PURPOSES?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="odCcLimitsUtilisedForPersonalOrLongTerm"
              checked={formData.odCcLimitsUtilisedForPersonalOrLongTerm}
              onChange={handleChange}
            />
          </Form.Group>

          {formData.odCcLimitsUtilisedForPersonalOrLongTerm && (
            <Form.Group as={Col} controlId="odCcLimitsUtilisationDetails">
              <Form.Label>IF YES, GIVE DETAILS</Form.Label>
              <Form.Control
                type="text"
                name="odCcLimitsUtilisationDetails"
                value={formData.odCcLimitsUtilisationDetails}
                onChange={handleChange}
              />
            </Form.Group>
          )}
          <Form.Group
            as={Col}
            controlId="odCcLimitsUtilisedForPersonalOrLongTermRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="odCcLimitsUtilisedForPersonalOrLongTermRemark"
              value={formData.odCcLimitsUtilisedForPersonalOrLongTermRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* // */}

        {/*  */}

        {/* // SUNDRY DEBTORS    */}
        <h6 className="mt-5">SUNDRY DEBTORS</h6>
        <hr className="mb-5" />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="sundryDebtorsList">
            <Form.Label>LIST OF SUNDRY DEBTORS AS ON THE YEAR END</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter list of sundry debtors"
              name="sundryDebtorsList"
              value={formData.sundryDebtorsList}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="outstandingAmountOverSixMonths">
            <Form.Label>
              WHAT IS THE OUTSTANDING AMOUNT OVER 6 MONTHS?
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter amount"
              name="outstandingAmountOverSixMonths"
              value={formData.outstandingAmountOverSixMonths}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="amountOutstandingOverOneYear">
            <Form.Label>WHAT IS THE AMOUNT OUTSTANDING OVER 1 YEAR?</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter amount"
              name="amountOutstandingOverOneYear"
              value={formData.amountOutstandingOverOneYear}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="verifiedAccountStatementWithDebtors">
            <Form.Label>
              VERIFIED THE COPY OF ACCOUNT STATEMENT WITH SIGNIFICANT DEBTORS?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="verifiedAccountStatementWithDebtors"
              checked={formData.verifiedAccountStatementWithDebtors}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            controlId="verifiedAccountStatementWithDebtorsRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="verifiedAccountStatementWithDebtorsRemark"
              value={formData.verifiedAccountStatementWithDebtorsRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        {/* // SUNDRY CREDITORS
         */}
        <h6 className="mt-5">SUNDRY CREDITORS</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="sundryCreditorList">
            <Form.Label>LIST OF SUNDRY CREDITORS</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter list of sundry debtors"
              name="sundryCreditorsList"
              value={formData.sundryCreditorsList}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="outstandingAmountOverSixMonthsCreditor"
          >
            <Form.Label>
              WHAT IS THE OUTSTANDING AMOUNT OVER 6 MONTHS?
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter outstanding amount"
              name="outstandingAmountOverSixMonthsCreditor"
              value={formData.outstandingAmountOverSixMonthsCreditor}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="amountOutstandingOverOneYearCreditor">
            <Form.Label>WHAT IS THE AMOUNT OUTSTANDING OVER 1 YEAR?</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter amount outstanding over 1 year"
              name="amountOutstandingOverOneYearCreditor"
              value={formData.amountOutstandingOverOneYearCreditor}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="numberOfSMEVendors">
            <Form.Label>
              WHAT IS THE NUMBER OF SME VENDORS / SUPPLIERS?
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter number of SME vendors"
              name="numberOfSMEVendors"
              value={formData.numberOfSMEVendors}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="numberOfAccountsOutstandingOver15or45Days"
          >
            <Form.Label>NO OF ACCOUNTS O/S OVER 15/45 DAYS</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter number of accounts outstanding over 15/45 days"
              name="numberOfAccountsOutstandingOver15or45Days"
              value={formData.numberOfAccountsOutstandingOver15or45Days}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="amountOutstandingOver15or45Days">
            <Form.Label>AMOUNT O/S OVER 15/45 DAYS</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter amount outstanding over 15/45 days"
              name="amountOutstandingOver15or45Days"
              value={formData.amountOutstandingOver15or45Days}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="disallowanceAmountUnder44BH">
            <Form.Label>DISALLOWANCE AMOUNT U/S 44 B(H)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter disallowance amount"
              name="disallowanceAmountUnder44BH"
              value={formData.disallowanceAmountUnder44BH}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="verifiedAccountStatementWithCreditors"
          >
            <Form.Label>
              VERIFIED THE COPY OF ACCOUNT STATEMENT WITH SIGNIFICANT CREDITORS?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="verifiedAccountStatementWithCreditors"
              checked={formData.verifiedAccountStatementWithCreditors}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="verifiedAccountStatementWithCreditorsRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="verifiedAccountStatementWithCreditorsRemark"
              value={formData.verifiedAccountStatementWithCreditorsRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* // Investments
         */}
        <h6 className="mt-5">Investments</h6>
        <hr className="mb-5" />
        <Row>
          <Form.Group as={Col} controlId="investmentsInEntity">
            <Form.Label>
              HAVE ANY INVESTMENTS IN THE NAME OF THE ENTITY?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="investmentsInEntity"
              checked={formData.investmentsInEntity}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="investmentsInEntityRemark">
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="investmentsInEntityRemark"
              value={formData.investmentsInEntityRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        {formData.investmentsInEntity && (
          <Row>
            <Form.Group as={Col} controlId="verifiedInvestmentDetails">
              <Form.Label>VERIFIED THE DETAILS</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter details"
                name="verifiedInvestmentDetails"
                value={formData.verifiedInvestmentDetails}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="investmentIncome">
              <Form.Label>WHAT IS THE INCOME FROM INVESTMENT?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter investment income"
                name="investmentIncome"
                value={formData.investmentIncome}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        )}

        <Row className="my-3">
          <Form.Group as={Col} controlId="verifyInvestmentBalanceSheets">
            <Form.Label>
              IN RESPECT OF INVESTMENTS IN SHARES, VERIFY THE YEAR’S BALANCE
              SHEETS UNDER AUDIT IN RESPECT OF INVESTED COMPANIES.
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="verifyInvestmentBalanceSheets"
              checked={formData.verifyInvestmentBalanceSheets}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="verifyInvestmentBalanceSheetsRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="verifyInvestmentBalanceSheetsRemark"
              value={formData.verifyInvestmentBalanceSheetsRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="obtainBrokerContractNotes">
            <Form.Label>
              IN CASE OF INVESTMENT IN LISTED SHARES – COPY OF ALL CONTRACT
              NOTES FROM THE BROKERS TO BE OBTAINED
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="obtainBrokerContractNotes"
              checked={formData.obtainBrokerContractNotes}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="obtainBrokerContractNotesRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="obtainBrokerContractNotesRemark"
              value={formData.obtainBrokerContractNotesRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="obtainCapitalGainLossComputation">
            <Form.Label>
              CAPITAL GAIN / (LOSS) COMPUTATION TO BE OBTAINED AND TO BE TALLIED
              WITH BOOKS OF ACCOUNTS
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="obtainCapitalGainLossComputation"
              checked={formData.obtainCapitalGainLossComputation}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="obtainCapitalGainLossComputationRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="obtainCapitalGainLossComputationRemark"
              value={formData.obtainCapitalGainLossComputationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="complianceWithCompaniesAct">
            <Form.Label>
              WHETHER INVESTMENT IN SHARES IN ANY RELATED PARTY COMPLIES WITH
              COMPANIES ACT, 2013
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="complianceWithCompaniesAct"
              checked={formData.complianceWithCompaniesAct}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="complianceWithCompaniesActRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="complianceWithCompaniesActRemark"
              value={formData.complianceWithCompaniesActRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* //START FROM CARGO */}
        {/* // Cargo
         */}
        <h6 className="mt-5">Cargo</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="isCAROApplicable">
            <Form.Label>IS CARO APPLICABLE?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="isCAROApplicable"
              checked={formData.isCAROApplicable}
              onChange={handleChange}
            />
          </Form.Group>
          {formData.isCAROApplicable && (
            <Form.Group as={Col} controlId="reasonForApplicability">
              <Form.Label>Reason for Applicability</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reason"
                name="reasonForApplicability"
                value={formData.reasonForApplicability}
                onChange={handleChange}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="isCAROApplicableRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="isCAROApplicableRemark"
              value={formData.isCAROApplicableRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* // Finance cost
         */}
        <h6 className="mt-5">FINANCE COST</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="apportionmentOfPrincipalAndInterest">
            <Form.Label>
              CHECK THE APPORTIONMENT OF PRINCIPAL & INTEREST AMOUNT AS PER LOAN
              AMORTISATION SCHEDULE.
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="apportionmentOfPrincipalAndInterest"
              checked={formData.apportionmentOfPrincipalAndInterest}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="apportionmentOfPrincipalAndInterestRemake"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="apportionmentOfPrincipalAndInterestRemake"
              value={formData.apportionmentOfPrincipalAndInterestRemake}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="tdsDeductedOnInterestPayment">
            <Form.Label>
              WHETHER TDS UNDER SECTION 194A HAS BEEN DEDUCTED ON THE INTEREST
              PAYMENT TO NBFC OR INDIVIDUALS OR CORPORATES?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="tdsDeductedOnInterestPayment"
              checked={formData.tdsDeductedOnInterestPayment}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="dsDeductedOnInterestPaymentRemake">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="dsDeductedOnInterestPaymentRemake"
              value={formData.dsDeductedOnInterestPaymentRemake}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="properEntryInBooksOfAccounts">
            <Form.Label>
              WHETHER PROPER ENTRY HAS BEEN MADE IN THE BOOKS OF ACCOUNTS
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="properEntryInBooksOfAccounts"
              checked={formData.properEntryInBooksOfAccounts}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="properEntryInBooksOfAccountsRemake">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="properEntryInBooksOfAccountsRemake"
              value={formData.properEntryInBooksOfAccountsRemake}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* DEFERRED TAX ASSETS / LIABILITIES */}
        <h6 className="mt-5">DEFERRED TAX ASSETS / LIABILITIES</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="computationChecked">
            <Form.Label>CHECK THE COMPUTATION</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="computationChecked"
              checked={formData.computationChecked}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="computationCheckedRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="computationCheckedRemark"
              value={formData.computationCheckedRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* LOANS AND ADVANCES
         */}
        <h6 className="mt-5">LOANS AND ADVANCES</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="loanGrantedToRelatedParty">
            <Form.Label>
              WHETHER LOAN HAS BEEN GRANTED TO RELATED PARTY AND RELATIVES
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="loanGrantedToRelatedParty"
              checked={formData.loanGrantedToRelatedParty}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="loanGrantedToRelatedPartyRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="loanGrantedToRelatedPartyRemark"
              value={formData.loanGrantedToRelatedPartyRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="loanAndAdvanceAuthorization">
            <Form.Label>
              WHETHER BOD AND COPY OF AGREEMENT APPROPRIATELY AUTHORIZE LOAN AND
              ADVANCE MADE TO OTHER
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="loanAndAdvanceAuthorization"
              checked={formData.loanAndAdvanceAuthorization}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="loanAndAdvanceAuthorizationRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="loanAndAdvanceAuthorizationRemark"
              value={formData.loanAndAdvanceAuthorizationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="cashReceiptsAndPaymentsToLoanAccount">
            <Form.Label>
              IS THERE ANY CASH RECEIPTS AND PAYMENTS TO LOAN ACCOUNT ?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="cashReceiptsAndPaymentsToLoanAccount"
              checked={formData.cashReceiptsAndPaymentsToLoanAccount}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="cashReceiptsAndPaymentsToLoanAccountRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="cashReceiptsAndPaymentsToLoanAccountRemark"
              value={formData.cashReceiptsAndPaymentsToLoanAccountRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* //SECURITY */}

        <h6 className="mt-5">SECURITY</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="securityAgreement">
            <Form.Label>
              IN CASE THE COMPANY GIVES SECURITY TO THE PARTY, OBTAIN A SECURITY
              AGREEMENT FROM THE PARTY
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="securityAgreement"
              checked={formData.securityAgreement}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="securityAgreementRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="securityAgreementRemark"
              value={formData.securityAgreementRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="securityRefundable">
            <Form.Label>VERIFY SECURITY IS REFUNDABLE OR NOT</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="securityRefundable"
              checked={formData.securityRefundable}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="securityRefundableRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="securityRefundableRemark"
              value={formData.securityRefundableRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="securityReturnAgreement">
            <Form.Label>
              VERIFY THE AGREEMENT WHETHER THERE IS AN AGREEMENT REGARDING
              RETURNS ON SECURITY AND SUCH RETURN RECOGNIZED IN THE BOOK OF
              ACCOUNT
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="securityReturnAgreement"
              checked={formData.securityReturnAgreement}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="securityReturnAgreementRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="securityReturnAgreementRemark"
              value={formData.securityReturnAgreementRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* //CASH
         */}

        <h6 className="mt-5">CASH</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="obtainSignedCashBalanceCertificate">
            <Form.Label>
              OBTAIN SIGNED CASH BALANCE CERTIFICATE FROM CASHIER AND DIRECTOR
              AS WELL AS ON MARCH 31ST
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="obtainSignedCashBalanceCertificate"
              checked={formData.obtainSignedCashBalanceCertificate}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="obtainSignedCashBalanceCertificateRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="obtainSignedCashBalanceCertificateRemark"
              value={formData.obtainSignedCashBalanceCertificateRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* //Iventory
         */}

        <h6 className="mt-5">INVENTORY</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="receivedClosingStockConfirmation">
            <Form.Label>
              RECEIVED SIGNED CLOSING STOCK CONFIRMATION AS ON MARCH 31ST?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="receivedClosingStockConfirmation"
              checked={formData.receivedClosingStockConfirmation}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="receivedClosingStockConfirmationRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="receivedClosingStockConfirmationRemark"
              value={formData.receivedClosingStockConfirmationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="receivedStockLossConfirmation">
            <Form.Label>
              RECEIVED CONFIRMATION OF STOCK LOSS AND ABNORMAL WASTAGE?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="receivedStockLossConfirmation"
              checked={formData.receivedStockLossConfirmation}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="receivedStockLossConfirmationRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="receivedStockLossConfirmationRemark"
              value={formData.receivedStockLossConfirmationRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="revenueFromOperations">
            <Form.Label>REVENUE FROM OPERATIONS</Form.Label>
            <Form.Control
              type="text"
              name="revenueFromOperations"
              value={formData.revenueFromOperations}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="copyOfInvoices">
            <Form.Label>
              COPY OF INVOICES (AT LEAST 5 TO 10) TO BE OBTAINED FOR VARIOUS
              MONTHS FOR RECORDS
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="copyOfInvoices"
              checked={formData.copyOfInvoices}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* PURCHASES */}

        <h6 className="mt-5">Purchase</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="purchaseAndInvoiceDetails">
            <Form.Label>
              PURCHASES COPY OF INVOICES AT LEAST 5 TO 10 TO BE OBTAINED FOR
              VARIOUS MONTHS FOR RECORDS
            </Form.Label>
            <Form.Control
              type="text"
              name="purchaseAndInvoiceDetails"
              value={formData.purchaseAndInvoiceDetails}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* Salary */}

        <h6 className="mt-5">SALARY</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="tdsApplicabilitySalary">
            <Form.Label>CHECK APPLICABILITY OF TDS FOR SALARY</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="tdsApplicabilitySalary"
              checked={formData.tdsApplicabilitySalary}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="tdsApplicabilitySalaryRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="tdsApplicabilitySalaryRemark"
              value={formData.tdsApplicabilitySalaryRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="tdsObservations">
            <Form.Label>ANY SHORT/NON DEDUCTION CASES OBSERVED?</Form.Label>
            <Form.Control
              type="text"
              name="tdsObservations"
              value={formData.tdsObservations}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* ESI/EPF */}
        <h6 className="mt-5">ESI / EFI</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="totalEmployees">
            <Form.Label>TOTAL NUMBER OF EMPLOYEES</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter total number of employees"
              name="totalEmployeesForESIEFI"
              value={formData.totalEmployeesForESIEFI}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="esiCoveredEmployees">
            <Form.Label>NUMBER OF EMPLOYEES COVERED UNDER ESI</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number of employees covered under ESI"
              name="esiCoveredEmployees"
              value={formData.esiCoveredEmployees}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="registeredUnderESI">
            <Form.Label>REGISTERED UNDER ESI?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="registeredUnderESI"
              checked={formData.registeredUnderESI}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="esiObservationsShort">
            <Form.Label>ANY ESI SHORT DEDUCTION OBSERVED?</Form.Label>
            <Form.Control
              type="text"
              name="esiObservationsShort"
              value={formData.esiObservationsShort}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="esiObservationsNon">
            <Form.Label>ANY ESI NON DEDUCTION OBSERVED?</Form.Label>
            <Form.Control
              type="text"
              name="esiObservationsNon"
              value={formData.esiObservationsNon}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="registeredUnderEPF">
            <Form.Label>REGISTERED UNDER EPF?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="registeredUnderEPF"
              checked={formData.registeredUnderEPF}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="epfObservationsShort">
            <Form.Label>ANY ESI SHORT DEDUCTION OBSERVED?</Form.Label>
            <Form.Control
              type="text"
              name="epfObservationsShort"
              value={formData.epfObservationsShort}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="epfObservationsNon">
            <Form.Label>ANY EPF NON DEDUCTION OBSERVED?</Form.Label>
            <Form.Control
              type="text"
              name="epfObservationsNon"
              value={formData.epfObservationsNon}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="disallowances">
            <Form.Label>
              ANY DISALLOWANCES RELATED TO PAYMENTS/DEDUCTIONS?
            </Form.Label>
            <Form.Control
              type="text"
              name="disallowances"
              value={formData.disallowances}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* Other Expenses */}
        <h6 className="mt-5">Other Expenses</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="gstReverseChargeExpenses">
            <Form.Label>
              LIST OUT THE EXPENSES ON WHICH GST REVERSE CHARGE APPLIED &
              ENTERED ADEQUATELY IN BOOKS OF ACCOUNTS?
            </Form.Label>
            <Form.Control
              type="text"
              name="gstReverseChargeExpenses"
              value={formData.gstReverseChargeExpenses}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="personalCharityClubExpenses">
            <Form.Label>
              PERSONAL NATURE/CHARITY DONATION/CLUB EXPENSES (FOR TAX AUDIT
              PURPOSE)
            </Form.Label>
            <Form.Control
              type="text"
              name="personalCharityClubExpenses"
              value={formData.personalCharityClubExpenses}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="regularlyOccurringExpenses">
            <Form.Label>
              EXAMINE EXPENSE OCCURS REGULARLY., FOR EX. ELECTRICITY EXP. BOOKED
              EVERY MONTH OR SALARY EXP. BOOKED EVERY MONTH
            </Form.Label>
            <Form.Control
              type="text"
              name="regularlyOccurringExpenses"
              value={formData.regularlyOccurringExpenses}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="yearEndExpenseEntries">
            <Form.Label>
              EXAMINE WHETHER COMPANY HAD MADE EXPENSE DUE ENTRY AT THE
              YEAR-END, FOR EX. SALARY OF MARCH MONTH BOOKED
            </Form.Label>
            <Form.Control
              type="text"
              name="yearEndExpenseEntries"
              value={formData.yearEndExpenseEntries}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="capitalNatureExpenses">
            <Form.Label>
              CHECK ANY EXPENDITURE OF CAPITAL NATURE CHARGE IN REVENUE EXPENSES
            </Form.Label>
            <Form.Control
              type="text"
              name="capitalNatureExpenses"
              value={formData.capitalNatureExpenses}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="gstInputClaim">
            <Form.Label>
              CHECK WHETHER GST TAX INPUT CLAIM ON ANY EXPENSES
            </Form.Label>
            <Form.Control
              type="text"
              name="gstInputClaim"
              value={formData.gstInputClaim}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="gstReturnGSTR">
            <Form.Label>
              CHECK SUCH INPUT CLAIM IN GST RETURN IN GSTR
            </Form.Label>
            <Form.Control
              type="text"
              name="gstReturnGSTR"
              value={formData.gstReturnGSTR}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="gstReturnComparison">
            <Form.Label>
              COMPARE THE EXPENSES WITH PY REPORT FLUCTUATIONS
            </Form.Label>
            <Form.Control
              type="text"
              name="gstReturnComparison"
              value={formData.gstReturnComparison}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* Change in OwnerShip */}
        <h6 className="mt-5">CHANGE IN OWNERSHIP</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="changesOwnershipDuringPY">
            <Form.Label>
              ANY CHANGES IN OWNERSHIP DURING THE PREVIOUS YEAR?
            </Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="changesOwnershipDuringPY"
              checked={formData.changesOwnershipDuringPY}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="changesOwnershipDuringPYRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="changesOwnershipDuringPYRemark"
              value={formData.changesOwnershipDuringPYRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {formData.changesOwnershipDuringPY && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="detailsChangesOwnership">
              <Form.Label>DETAILS OF SUCH CHANGES</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter details of changes in ownership"
                name="detailsChangesOwnership"
                value={formData.detailsChangesOwnership}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        )}

        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="documentsVerificationChangesOwnership"
          >
            <Form.Label>DOCUMENTS RECEIVED FOR VERIFICATION</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="documentsVerificationChangesOwnership"
              checked={formData.documentsVerificationChangesOwnership}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="documentsVerificationChangesOwnershipRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="documentsVerificationChangesOwnershipRemark"
              value={formData.documentsVerificationChangesOwnershipRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="anyShareTransferAuditPeriod"
            >
              <Form.Label>ANY SHARE TRANSFER MADE DURING THE AUDIT PERIOD</Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="anyShareTransferAuditPeriod"
                checked={formData.anyShareTransferAuditPeriod}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="anyShareTransferAuditPeriodRemark"
            >
              <Form.Label></Form.Label>
              <Form.Control
                placeholder="Add remarks..."
                type="text"
                name="anyShareTransferAuditPeriodRemark"
                value={formData.anyShareTransferAuditPeriodRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          {formData.anyShareTransferAuditPeriod && (
            <>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="sh4Received">
                <Form.Label>SH4 RECEIVED</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Yes"
                  name="sh4Received"
                  checked={formData.sh4Received}
                  onChange={handleChange}
                />
              </Form.Group>
              {!formData.sh4Received && (
                <Form.Group as={Col} controlId="sh4ReceivedRemark">
                  <Form.Label></Form.Label>
                  <Form.Control
                    placeholder="Add remarks..."
                    type="text"
                    name="sh4ReceivedRemark"
                    value={formData.sh4ReceivedRemark}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Row>
          

          <Row className="mb-3">
            <Form.Group as={Col} controlId="noOfShareTransfer">
              <Form.Label>NUMBER OF SHARE TRANSFERED</Form.Label>
              <Form.Control
                type="text"
                name="noOfShareTransfer"
                value={formData.noOfShareTransfer}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="nameTransferer">
              <Form.Label>NAME OF THE TRANSFERER</Form.Label>
              <Form.Control
                type="text"
                name="nameTransferer"
                value={formData.nameTransferer}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="nameTransferee">
              <Form.Label>NAME OF THE TRANSFEREE</Form.Label>
              <Form.Control
                type="text"
                name="nameTransferee"
                value={formData.nameTransferee}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          </>
)}

<Row className="mb-3">
            <Form.Group as={Col} controlId="changeInDirector">
              <Form.Label>CHANGE IN DIRECTOR</Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="changeInDirector"
                checked={formData.changeInDirector}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="changeInDirectorRemark">
              <Form.Label></Form.Label>
              <Form.Control
                placeholder="Add remarks..."
                type="text"
                name="changeInDirectorRemark"
                value={formData.changeInDirectorRemark}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="dpt3applicable">
              <Form.Label>DPT3 APPLICABLE</Form.Label>
              <Form.Check
                type="checkbox"
                label="Yes"
                name="dpt3applicable"
                checked={formData.dpt3applicable}
                onChange={handleChange}
              />
            </Form.Group>

            {formData.dpt3applicable && (
              <Form.Group as={Col} controlId="dpt3applicableLoan">
                <Form.Label>LOAN AMOUNT</Form.Label>
                <Form.Control
                  type="text"
                  name="dpt3applicableLoan"
                  value={formData.dpt3applicableLoan}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Row>


        {/* //Changes In Management */}

        {/* Change in OwnerShip */}
        <h6 className="mt-5">CHANGE IN MANAGEMENT</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="managementChanges">
            <Form.Label>ANY CHANGES DURING THE PY?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="managementChanges"
              checked={formData.managementChanges}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="managementChangesRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="managementChangesRemark"
              value={formData.managementChangesRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {formData.managementChanges && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="detailsManagementChanges">
              <Form.Label>DETAILS OF SUCH CHANGES</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter details"
                name="detailsManagementChanges"
                value={formData.detailsManagementChanges}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        )}

        <Row className="mb-3">
          <Form.Group as={Col} controlId="verificationDocumentsManagement">
            <Form.Label>DOCUMENTS RECEIVED FOR VERIFICATION?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="verificationDocumentsManagement"
              checked={formData.verificationDocumentsManagement}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="verificationDocumentsManagementRemark"
          >
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="verificationDocumentsManagementRemark"
              value={formData.verificationDocumentsManagementRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* Grant /Subsides */}
        <h6 className="mt-5">Grant /Subsides</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="receivedGrantsSubsidies">
            <Form.Label>HAVE RECEIVED ANY GRANTS / SUBSIDIES?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="receivedGrantsSubsidies"
              checked={formData.receivedGrantsSubsidies}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="receivedGrantsSubsidiesRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="receivedGrantsSubsidiesRemark"
              value={formData.receivedGrantsSubsidiesRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {formData.receivedGrantsSubsidies && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="natureGrantsSubsidies">
              <Form.Label>WHAT IS THE NATURE OF GRANT/SUBSIDY?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter nature of grant/subsidy"
                name="natureGrantsSubsidies"
                value={formData.natureGrantsSubsidies}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="refundable">
            <Form.Label>REFUNDABLE / NOT?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="refundable"
              checked={formData.refundable}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="refundableRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="refundableRemark"
              value={formData.refundableRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="accountingTreatment">
            <Form.Label>WHAT IS THE ACCOUNTING TREATMENT?</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter accounting treatment"
              name="accountingTreatment"
              value={formData.accountingTreatment}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="conditions">
            <Form.Label>CONDITIONS WITH GRANTS/ SUBSIDIES</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter conditions"
              name="conditions"
              value={formData.conditions}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="complianceConditions">
            <Form.Label>COMPLIANCE WITH THE CONDITIONS</Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="complianceConditions"
              value={formData.complianceConditions}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="accountingTreatmentGrants">
            <Form.Label>ACCOUNTING TREATMENT ON THIS</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter accounting treatment for grants"
              name="accountingTreatmentGrants"
              value={formData.accountingTreatmentGrants}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        {/* DISPUTES AND ARBITRATIONS */}
        <h6 className="mt-5">DISPUTES AND ARBITRATIONS</h6>
        <hr className="mb-5" />

        <Row className="mb-3">
          <Form.Group as={Col} controlId="pendingDisputes">
            <Form.Label>ANY PENDING DISPUTES?</Form.Label>
            <Form.Check
              type="checkbox"
              label="Yes"
              name="pendingDisputes"
              checked={formData.pendingDisputes}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pendingDisputesRemark">
            <Form.Label></Form.Label>
            <Form.Control
              placeholder="Add remarks..."
              type="text"
              name="pendingDisputesRemark"
              value={formData.pendingDisputesRemark}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="detailedVerification">
            <Form.Label>DETAILED RECEIVED FOR VERIFICATION</Form.Label>
            <Form.Control
              type="text"
              name="detailedVerification"
              value={formData.detailedVerification}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} controlId="obsRemarks_100">
              <Form.Label>ANY OBSERVATIONS OR REMARKS</Form.Label>
              <Form.Control
                type="text"
                name="obsRemarks_100"
                value={formData.obsRemarks_100}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        <Row className="pb-5 d-flex justify-content-center">
          <Button
            className="mt-5 update_btn"
            style={{ maxWidth: "300px" }}
            variant="danger"
            type="submit"
          >
            Update
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default UpdateCheckList;
