import React, { Fragment, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCheckList } from "../../actions/checkListActions";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../layouts/Loader";
import logo from "../../assets/Bizpole_logo.png";

const ViewCheckList = () => {
  const { loading, checkList } = useSelector((state) => state.checkListState);
  const componentRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getCheckList(id));
  }, [dispatch, id]);

  const handleUpdate = () => {
    navigate(`/update/${id}`);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <Fragment>
      {loading || !checkList ? (
        <Loader />
      ) : (
        <Fragment>
          <Container>
            <div>
              <Row className="justify-content-end">
                <Col md={3} className="d-flex m-3">
                  <button
                    className="btn btn-danger m-2"
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Update form
                  </button>
                  <button
                    className="btn btn-success m-2"
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    Print this out!
                  </button>
                </Col>
              </Row>
              <div className="p-4" ref={componentRef}>
                <Table bordered hover>
                  <tbody>
                    <tr className="text-center">
                      <td style={{ verticalAlign: "middle" }}>
                        <img src={logo} alt="" style={{ maxWidth: "150px" }} />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <strong>AUDIT CHECKLIST V-001</strong>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "50%", textTransform: "capitalize" }}>
                        ORDER DATE:
                      </td>
                      <td style={{ width: "50%" }}>
                        {" "}
                        {checkList.orderDate &&
                          formattedDate(checkList.orderDate)}
                      </td>
                    </tr>
                    <tr>
                      <td>TARGET DATE:</td>
                      <td>
                        {checkList.targetDate &&
                          formattedDate(checkList.targetDate)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <strong> Basic Verifications</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>BUSINESS NAME:</td>
                      <td>{checkList.businessName}</td>
                    </tr>
                    <tr>
                      <td>BUSINESS FORMAT:</td>
                      <td>{checkList.businessFormat}</td>
                    </tr>
                    <tr>
                      <td>PLACE OF BUSINESS:</td>
                      <td>{checkList.placeOfBusiness}</td>
                    </tr>
                    <tr>
                      <td>STATE:</td>
                      <td>{checkList.state}</td>
                    </tr>
                    <tr>
                      <td>BUSINESS ACTIVITY:</td>
                      <td>{checkList.businessActivity}</td>
                    </tr>
                    <tr>
                      <td>DATE OF INCORPORATION</td>
                      <td>{checkList.doi && formattedDate(checkList.doi)}</td>
                    </tr>
                    <tr>
                      <td>YEAR OF AUDIT</td>
                      <td>{checkList.yoa_100}</td>
                    </tr>
                    <tr>
                      <td>FIRST / SUBSEQUENT</td>
                      <td>{checkList.yoa}</td>
                    </tr>
                    <tr>
                      <td>IS THIS YEAR'S AUDITOR THE SAME AS LAST YEAR'S ?</td>
                      <td>
                        {checkList.isThisYearsAuditorTheSame ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.isThisYearsAuditorTheSameRemarks && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.isThisYearsAuditorTheSameRemarks}</td>
                      </tr>
                    )}
                    <tr>
                      <td>PERIOD OF APPOINTMENT FOR THE CURRENT AUDITOR?</td>
                      <td>{checkList.currentYearAuditorPeriod}</td>
                    </tr>
                    <tr>
                      <td>IS RENEWAL OF APPOINTMENT DUE?</td>
                      <td>
                        {checkList.currentYearAuditorRenewalDue ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>SRN OF FORM ADT 1 FILED.</td>
                      <td>
                        {checkList.currentYearAuditorRenewalSRN ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF NO, THEN WHO IS THE PREVIOUS AUDITOR ?</td>
                      <td>{checkList.ifNoPreviousAuditor}</td>
                    </tr>
                    <tr>
                      <td>WHO IS THE CURRENT YEAR AUDITOR ?</td>
                      <td>{checkList.currentYearAuditor}</td>
                    </tr>

                    <tr>
                      <td>
                        WHETHER THE FORM ADT 3 HAS BEEN FILED BY THE PRE AUDITOR
                        ?
                      </td>
                      <td>{checkList.filedByPreAuditor ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>IF YES, PLEASE MENTION SRN NUMBER</td>
                      <td>{checkList.srnNumberADT3}</td>
                    </tr>

                    {checkList.filedByPreAuditorRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.filedByPreAuditorRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>WHETHER THE FORM ADT 3 HAS BEEN VERIFIED ?</td>
                      <td>{checkList.formADT3Verified ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.formADT3VerifiedRemarks && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.formADT3VerifiedRemarks}</td>
                      </tr>
                    )}
                    {/* //first check  */}
                    <tr>
                      <td>
                        WHETHER THE NOC FROM PRE AUDITOR HAS BEEN RECEIVED ?
                      </td>
                      <td>
                        {checkList.nocReceivedFromPreAuditor ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.nocReceivedFromPreAuditorRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.nocReceivedFromPreAuditorRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        WHETHER THE FORM ADT 1 FILED BY THE COMPANY FOR
                        APPOINTMENT OF NEW AUDITOR ?
                      </td>
                      <td>{checkList.formADT1Filed ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>IF YES, PLEASE MENTION SRN NUMBER</td>
                      <td>{checkList.srnNumberADT1}</td>
                    </tr>

                    {checkList.formADT1FiledRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.formADT1FiledRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>WHETHER THE FORM ADT 1 HAS BEEN VERIFIED ?</td>
                      <td>{checkList.formADT1Verified ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.formADT1VerifiedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.formADT1VerifiedRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        WHETHER THE FORM AOC 4 FOR THE PRE YEAR HAS BEEN FILED ?
                      </td>
                      <td>{checkList.formAOC4Filed ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.formAOC4FiledRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.formAOC4FiledRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        WHETHER THE FORM MGT 7A FOR THE PRE YEAR HAS BEEN FILED
                        ?
                      </td>
                      <td>{checkList.formMGT7AFiled ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.formMGT7AFiledRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.formMGT7AFiledRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>WHETHER PY AUDITED FINANCIALS & GST AR VERIFIED ?</td>
                      <td>
                        {checkList.pyAuditedFinancialsGSTVerified
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.pyAuditedFinancialsGSTVerifiedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.pyAuditedFinancialsGSTVerifiedRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>WHETHER THE FORM INC 20A IS FILED ?</td>
                      <td>{checkList.formINC20AFiled ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.formINC20AFiledRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.formINC20AFiledRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>IF YES, PLEASE MENTION SRN NUMBER</td>
                      <td>{checkList.formINC20AFiledSRN}</td>
                    </tr>

                    <tr>
                      <td>HOW MANY BANK ACCOUNTS DOES THE COMPANY HAVE?</td>
                      <td>{checkList.numberOfBankAccounts}</td>
                    </tr>
                    <tr>
                      <td>HOW MANY BANK ACCOUNTS RECEIVED ?</td>
                      <td>{checkList.numberOfBankAccountsReceived}</td>
                    </tr>

                    <tr>
                      <td>
                        DO THE STATEMENTS COVER EITHER THE WHOLE YEAR OR, IF
                        OPENED LATER, FROM THE OPENING DATE TO THE YEAR END?
                      </td>
                      <td>
                        {checkList.statementsCoverWholeYear ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.statementsCoverWholeYearRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.statementsCoverWholeYearRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        DOES THE OPENING BALANCES ARE MATCHING WITH THE PY
                        CLOSING FIGURES ?
                      </td>
                      <td>
                        {checkList.openingBalancesMatchingPY ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.openingBalancesMatchingPYRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.openingBalancesMatchingPYRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>HAVE REGISTERED UNDER GST ?</td>
                      <td>{checkList.hasRegisteredUnderGST ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.hasRegisteredUnderGSTRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.hasRegisteredUnderGSTRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Username: {checkList.gstLoginCredentialsUsername}</td>
                      <td>Password: {checkList.gstLoginCredentialsPassword}</td>
                    </tr>
                    <tr>
                      <td>HAVE REGISTERED UNDER INCOME TAX ?</td>
                      <td>
                        {checkList.hasRegisteredUnderIncomeTax ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.hasRegisteredUnderIncomeTaxRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.hasRegisteredUnderIncomeTaxRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        Username: {checkList.incomeTaxLoginCredentialsUsername}
                      </td>
                      <td>
                        Password: {checkList.incomeTaxLoginCredentialsPassword}
                      </td>
                    </tr>
                    <tr>
                      <td>HAVE REGISTERED UNDER ESI ?</td>
                      <td>{checkList.hasRegisteredUnderESI ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.hasRegisteredUnderESIRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.hasRegisteredUnderESIRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Username: {checkList.esiLoginCredentialsUsername}</td>
                      <td>Password: {checkList.esiLoginCredentialsUsername}</td>
                    </tr>
                    <tr>
                      <td>HAVE REGISTERED UNDER PF ?</td>
                      <td>{checkList.hasRegisteredUnderPF ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.hasRegisteredUnderPFRemar && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.hasRegisteredUnderPFRemar}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Username: {checkList.pfLoginCredentialsUsername}</td>
                      <td>Password: {checkList.pfLoginCredentialsPassword}</td>
                    </tr>
                    <tr>
                      <td>HAVE REGISTERED UNDER TRACES ?</td>
                      <td>
                        {checkList.hasRegisteredUnderTRACES ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.hasRegisteredUnderTRACESRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.hasRegisteredUnderTRACESRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        Username: {checkList.tracesLoginCredentialsUsername}
                      </td>
                      <td>
                        Password: {checkList.tracesLoginCredentialsPassword}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <strong>GOODS AND SERVICE TAX</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        GOODS AND SERVICS DEALING WITH & APPLICABLE GST RATES
                      </td>
                      <td>{checkList.goodsAndServicesDealing}</td>
                    </tr>
                    <tr>
                      <td>SALES TURNOVER RECONCILIATION</td>
                      <td>{checkList.salesTurnoverReconciliation}</td>
                    </tr>
                    <tr>
                      <td>PURCHASE TURNOVER RECONCILIATION</td>
                      <td>{checkList.purchaseTurnoverReconciliation}</td>
                    </tr>
                    <tr>
                      <td>GST INPUT & OUTPUT RECONCILIATION</td>
                      <td>{checkList.gstInputOutputReconciliation}</td>
                    </tr>
                    <tr>
                      <td>ANY EXPORT/IMPORT PROCEEDS ?</td>
                      <td>
                        {checkList.anyExportImportProceeds ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.anyExportImportProceedsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.anyExportImportProceedsRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>HAVE ANY EXPORTS TURNOVER ?</td>
                      <td>{checkList.haveAnyExportsTurnover ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.haveAnyExportsTurnoverRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.haveAnyExportsTurnoverRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>HAVE APPLIED FOR LUT IN GST PORTAL ?</td>
                      <td>{checkList.haveAppliedForLUT ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.haveAppliedForLUTRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.haveAppliedForLUTRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        COPY OF LUT TO BE OBTAINED FOR THE YEAR UNDER REVIEW
                      </td>
                      <td>{checkList.copyOfLUTToBeObtained}</td>
                    </tr>
                    <tr>
                      <td>
                        DELAYED PAYMENTS TO CREDITORS/VENDORS OVER 180 DAYS
                      </td>
                      <td>{checkList.delayedPaymentsToCreditors}</td>
                    </tr>
                    <tr>
                      <td>GST INPUT ON SUCH DELAYED TRANSACTIONS</td>
                      <td>{checkList.gstInputOnDelayedTransactions}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <strong>INCOME TAX</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>ANY TDS DEDUCTIONS DURING THE YEAR ?</td>
                      <td>
                        {checkList.anyTDSDeductionsDuringYear ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.anyTDSDeductionsDuringYearRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.anyTDSDeductionsDuringYearRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>PREPARED TDS&TCS RECONCILIATION ?</td>
                      <td>
                        {checkList.preparedTDSTCSReconciliation ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.preparedTDSTCSReconciliationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.preparedTDSTCSReconciliationRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>ANY TDS&TCS LOWER DEDUCTION OBSERVED</td>
                      <td>
                        {checkList.anyTDSTCSLowerDeductionObserved
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.anyTDSTCSLowerDeductionObservedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.anyTDSTCSLowerDeductionObservedRemark}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>IF YES GIVE DETAILS</td>
                      <td>{checkList.tdsTCSDetailsLowerDeduction}</td>
                    </tr>
                    <tr>
                      <td>ANY TDS&TCS NON DEDUCTION OBSERVED</td>
                      <td>
                        {checkList.anyTDSTCSNonDeductionObserved ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.tdsTCSNonDeductionDetailsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.tdsTCSNonDeductionDetailsRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>IF YES GIVE DETAILS</td>
                      <td>{checkList.tdsTCSNonDeductionDetails}</td>
                    </tr>

                    <tr>
                      <td>ANY TDS&TCS NON PAYMENTS OBSERVED</td>
                      <td>
                        {checkList.anyTDSTCSNonPaymentsObserved ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF YES GIVE DETAILS</td>
                      <td>{checkList.tdsTCSNonPaymentsDetails}</td>
                    </tr>
                    {checkList.anyTDSTCSNonPaymentsObservedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.anyTDSTCSNonPaymentsObservedRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>ANY DISALLOWANCE BASED ON THE ABOVE</td>
                      <td>
                        {checkList.anyDisallowanceBasedOnAbove ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF YES GIVE DETAILS</td>
                      <td>{checkList.disallowanceDetails}</td>
                    </tr>
                    {checkList.anyDisallowanceBasedOnAboveRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.anyDisallowanceBasedOnAboveRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>CHECK INCOME AS PER 26 AS & ACCOUNTS</td>
                      <td>
                        {checkList.checkIncomeAsPer26ASAccounts ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.checkIncomeAsPer26ASAccountsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.checkIncomeAsPer26ASAccountsRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        TDS&TCS CREDIT AS PER 26 AS & ACCOUNTS ARE MATCHING ?
                      </td>
                      <td>{checkList.tdsTCSCreditMatching ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.tdsTCSCreditMatchingRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.tdsTCSCreditMatchingRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        ANY ADDITIONAL REPORTING IN AIS NOT REFLECTED IN
                        ACCOUNTS ?
                      </td>
                      <td>
                        {checkList.additionalReportingAISNotReflectedInAccounts
                          ? "Yes"
                          : "No"}{" "}
                      </td>
                    </tr>
                    {checkList.additionalReportingAISNotReflectedInAccountsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {
                            checkList.additionalReportingAISNotReflectedInAccountsRemark
                          }
                        </td>
                      </tr>
                    )}

                    {/* Start from here */}

                    <tr>
                      <td colSpan={2}>
                        <strong>FIXED ASSETS</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>ANY PURCHASES DURING THE YEAR ?</td>
                      <td>{checkList.anyPurchasesDuringYear ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>IF YES , INVOICES FOR FIXED ASSETS ARE VERIFIED ?</td>
                      <td>
                        {checkList.invoicesForFixedAssetsVerified
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {checkList.anyPurchasesDuringYearRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.anyPurchasesDuringYearRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>CHECK GST INPUT CREDIT UTILIZATION</td>
                      <td>
                        {checkList.checkGSTInputCreditUtilisation
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {checkList.checkGSTInputCreditUtilisationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.checkGSTInputCreditUtilisationRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>ANY SALE OF FIXED ASSETS DURING THE YEAR ?</td>
                      <td>
                        {checkList.anySaleOfFixedAssetsDuringYear
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF YES , INVOICES FOR FIXED ASSETS ARE VERIFIED ?</td>
                      <td>
                        {checkList.invoicesForSaleOfFixedAssetsVerified
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {checkList.anySaleOfFixedAssetsDuringYearRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.anySaleOfFixedAssetsDuringYearRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>ANY COMPANY VEHICLES USED FOR PERSONAL PURPOSES ?</td>
                      <td>
                        {checkList.companyVehiclesUsedForPersonalPurposes
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>WHAT IS THE DISALLOWANCE ON THAT ?</td>
                      <td>
                        {checkList.disallowanceOnCompanyVehicles ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.companyVehiclesUsedForPersonalPurposesRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {
                            checkList.companyVehiclesUsedForPersonalPurposesRemark
                          }
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>BANK LOANS</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>HOW MANY BANK LOANS DOES THE COMPANY HAVE?</td>
                      <td>{checkList.bankLoansCount ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>HOW MANY BANK ACCOUNTS RECEIVED ?</td>
                      <td>
                        {checkList.bankAccountsLoanReceived ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        DO THE STATEMENTS COVER EITHER THE WHOLE YEAR OR, IF
                        LOAN AVAILED LATER, FROM THE OPENING DATE TO THE YEAR
                        END?
                      </td>
                      <td>{checkList.statementsCoverage ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.statementsCoverageRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.statementsCoverageRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>ANY NEW LOANS SANCTIONED DURING THE PY</td>
                      <td>
                        {checkList.newLoansSanctionedDuringPY ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.newLoansSanctionedDuringPYRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.newLoansSanctionedDuringPYRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>IS IT REQUIRED TO CREATE CHARGE WITH ROC ?</td>
                      <td>
                        {checkList.chargeCreationRequiredWithROC ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.chargeCreationRequiredWithROCRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.chargeCreationRequiredWithROCRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>CHARGE FORMS RECEIVED FOR VERIFICATION ?</td>
                      <td>
                        {checkList.chargeFormsReceivedForVerification
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF YES ENTER THE SRN</td>
                      <td>{checkList.chargeFormsSRN}</td>
                    </tr>
                    {checkList.chargeFormsReceivedForVerificationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.chargeFormsReceivedForVerificationRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>CHECK BANK LOAN UTILIZATION</td>
                      <td>
                        {checkList.bankLoanUtilisationChecked ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.bankLoanUtilisationCheckedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.bankLoanUtilisationCheckedRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        ANY UTILIZATION FOR OTHER THAN THE PURPOSE FOR WHICH THE
                        LOAN SANCTIONED?
                      </td>
                      <td>
                        {checkList.otherPurposeUtilisationDetails
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF YES , GIVE DETAILS</td>
                      <td>{checkList.otherPurposeUtilisationDetailsDetais}</td>
                    </tr>

                    {checkList.otherPurposeUtilisationDetailsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.otherPurposeUtilisationDetailsRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        ANY OD/CC LIMITES SACTIONED UTILISED FOR PERSONAL / LONG
                        TERM PURPOSES ?
                      </td>
                      <td>
                        {checkList.odCcLimitsUtilisedForPersonalOrLongTerm
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>IF YES , GIVE DETAILS</td>
                      <td>{checkList.odCcLimitsUtilisationDetails}</td>
                    </tr>
                    {checkList.odCcLimitsUtilisedForPersonalOrLongTermRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {
                            checkList.odCcLimitsUtilisedForPersonalOrLongTermRemark
                          }
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>SUNDRY DEBTORS</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>LIST OF SUNDRY DEBTORS AS ON THE YEAR END</td>
                      <td>{checkList.sundryDebtorsList}</td>
                    </tr>
                    <tr>
                      <td>WHAT IS THE OUTSTANDING AMOUNT OVER 6 MONTHS ?</td>
                      <td>{checkList.outstandingAmountOverSixMonths}</td>
                    </tr>
                    <tr>
                      <td>WHAT IS THE AMOUNT OUTSTANDING OVER 1 YEAR</td>
                      <td>{checkList.amountOutstandingOverOneYear}</td>
                    </tr>
                    <tr>
                      <td>
                        VERIFIED THE COPY OF ACCOUNT STATEMENT WITH SIGNIFICANT
                        DEBTORS ?
                      </td>
                      <td>
                        {checkList.verifiedAccountStatementWithDebtors
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.verifiedAccountStatementWithDebtorsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.verifiedAccountStatementWithDebtorsRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>SUNDRY CREDITORS</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>LIST OF SUNDRY DEBTORS</td>
                      <td>{checkList.sundryCreditorsList}</td>
                    </tr>
                    <tr>
                      <td>WHAT IS THE OUTSTANDING AMOUNT OVER 6 MONTHS ?</td>
                      <td>
                        {checkList.outstandingAmountOverSixMonthsCreditor}
                      </td>
                    </tr>
                    <tr>
                      <td>WHAT IS THE AMOUNT OUTSTANDING OVER 1 YEAR</td>
                      <td>{checkList.amountOutstandingOverOneYearCreditor}</td>
                    </tr>
                    <tr>
                      <td>WHAT IS THE NUMBER OF SME VENDORS / SUPPLIERS</td>
                      <td>{checkList.numberOfSMEVendors}</td>
                    </tr>
                    <tr>
                      <td>NO OF ACCOUNTS O/S OVER 15/45 DAYS</td>
                      <td>
                        {checkList.numberOfAccountsOutstandingOver15or45Days}
                      </td>
                    </tr>
                    <tr>
                      <td>AMOUNT O/S OVER 15/45 DAYS</td>
                      <td>{checkList.amountOutstandingOver15or45Days}</td>
                    </tr>
                    <tr>
                      <td>DISALLOWANCE AMOUNT U/S 44 B(H)</td>
                      <td>{checkList.disallowanceAmountUnder44BH}</td>
                    </tr>
                    <tr>
                      <td>
                        VERIFIED THE COPY OF ACCOUNT STATEMENT WITH SIGNIFICANT
                        CREDITORS ?
                      </td>
                      <td>
                        {checkList.verifiedAccountStatementWithCreditors
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.verifiedAccountStatementWithCreditorsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {
                            checkList.verifiedAccountStatementWithCreditorsRemark
                          }
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>INVESTMENTS</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>HAVE ANY INVESTMENTS IN THE NAME OF THE ENTITY ?</td>
                      <td>{checkList.investmentsInEntity ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>IF , YES VERIFIED THE DETAILS</td>
                      <td>{checkList.verifiedInvestmentDetails}</td>
                    </tr>
                    {checkList.investmentsInEntityRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.investmentsInEntityRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>WHAT IS THE INCOME FROM INVESTMENT ?</td>
                      <td>{checkList.investmentIncome}</td>
                    </tr>
                    <tr>
                      <td>
                        IN RESPECT OF INVESTMENTS IN SHARES, VERIFY THE YEAR’S
                        BALANCE SHEETS UNDER AUDIT IN RESPECT OF INVESTED
                        COMPANIES.
                      </td>
                      <td>
                        {checkList.verifyInvestmentBalanceSheets ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.verifyInvestmentBalanceSheetsRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.verifyInvestmentBalanceSheetsRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        IN CASE OF INVESTMENT IN LISTED SHARES – COPY OF ALL
                        CONTRACT NOTES FROM THE BROKERS TO BE OBTAINED
                      </td>
                      <td>
                        {checkList.obtainBrokerContractNotes ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.obtainBrokerContractNotesRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.obtainBrokerContractNotesRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        CAPITAL GAIN / (LOSS) COMPUTATION TO BE OBTAINED AND TO
                        BE TALLIED WITH BOOKS OF ACCOUNTS
                      </td>
                      <td>
                        {checkList.obtainCapitalGainLossComputation
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.obtainCapitalGainLossComputationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.obtainCapitalGainLossComputationRemark}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        WHETHER INVESTMENT IN SHARES IN ANY RELATED PARTY
                        COMPLIES WITH COMPANIES ACT, 2013
                      </td>
                      <td>
                        {checkList.complianceWithCompaniesAct ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.complianceWithCompaniesActRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.complianceWithCompaniesActRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>CARO</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>IS CARO APPLICABLE ?</td>
                      <td>{checkList.isCAROApplicable ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>IF YES, REASON FOR APPLICABILITY ?</td>
                      <td>{checkList.reasonForApplicability}</td>
                    </tr>

                    {checkList.isCAROApplicableRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.isCAROApplicableRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>FINANCE COST</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        CHECK THE APPORTIONMENT OF PRINCIPAL & INTEREST AMOUNT
                        AS PER LOAN AMORTIZATION SCHEDULE.
                      </td>
                      <td>
                        {checkList.apportionmentOfPrincipalAndInterest
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {checkList.apportionmentOfPrincipalAndInterestRemake && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.apportionmentOfPrincipalAndInterestRemake}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        WHETHER TDS UNDER SECTION 194A HAS BEEN DEDUCTED ON THE
                        INTEREST PAYMENT TO NBFC OR INDIVIDUALS OR CORPORATES?
                      </td>
                      <td>
                        {checkList.tdsDeductedOnInterestPayment ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.tdsDeductedOnInterestPaymentRemake && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.tdsDeductedOnInterestPaymentRemake}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        WHETHER PROPER ENTRY HAS BEEN MADE IN THE BOOKS OF
                        ACCOUNTS
                      </td>
                      <td>
                        {checkList.properEntryInBooksOfAccounts ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.properEntryInBooksOfAccountsRemake && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.properEntryInBooksOfAccountsRemake}</td>
                      </tr>
                    )}

                    {/* ---------------------------------- */}
                    <tr>
                      <td colSpan={2}>
                        <strong> TAX ASSETS / LIABILITIES</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>CHECK THE COMPUTATION</td>
                      <td>{checkList.computationChecked ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.computationCheckedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.computationCheckedRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>LOANS AND ADVANCES</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        WHETHER LOAN HAS BEEN GRANTED TO RELATED PARTY AND
                        RELATIVES
                      </td>
                      <td>
                        {checkList.loanGrantedToRelatedParty ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.loanGrantedToRelatedPartyRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.loanGrantedToRelatedPartyRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        WHETHER BOD AND COPY OF AGREEMENT APPROPRIATELY
                        AUTHORIZE LOAN AND ADVANCE MADE TO OTHER
                      </td>
                      <td>
                        {checkList.loanAndAdvanceAuthorization ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.loanAndAdvanceAuthorizationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.loanAndAdvanceAuthorizationRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        IS THERE ANY CASH RECEIPTS AND PAYMENTS TO LOAN ACCOUNT
                        ?
                      </td>
                      <td>
                        {checkList.cashReceiptsAndPaymentsToLoanAccount
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.cashReceiptsAndPaymentsToLoanAccountRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.cashReceiptsAndPaymentsToLoanAccountRemark}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={2}>
                        <strong>SECURITY</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        IN CASE THE COMPANY GIVES SECURITY TO THE PARTY, OBTAIN
                        A SECURITY AGREEMENT FROM THE PARTY
                      </td>
                      <td>{checkList.securityAgreement ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.securityAgreementRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.securityAgreementRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>VERIFY SECURITY IS REFUNDABLE OR NOT</td>
                      <td>{checkList.securityRefundable ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.securityRefundableRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.securityRefundableRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        VERIFY THE AGREEMENT WHETHER THERE IS AN AGREEMENT
                        REGARDING RETURNS ON SECURITY AND SUCH RETURN RECOGNIZED
                        IN THE BOOK OF ACCOUNT
                      </td>
                      <td>
                        {checkList.securityReturnAgreement ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.securityReturnAgreementRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.securityReturnAgreementRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>CASH</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        OBTAIN SIGNED CASH BALANCE CERTIFICATE FROM CASHIER AND
                        DIRECTOR AS WELL AS ON MARCH 31ST
                      </td>
                      <td>
                        {checkList.obtainSignedCashBalanceCertificate
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {checkList.obtainSignedCashBalanceCertificateRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.obtainSignedCashBalanceCertificateRemark}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={2}>
                        <strong>INVENTORY</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        RECEIVED SIGNED CLOSING STOCK CONFIRMATION AS ON MARCH
                        31ST ?
                      </td>
                      <td>
                        {checkList.receivedClosingStockConfirmation
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    {checkList.receivedClosingStockConfirmationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.receivedClosingStockConfirmationRemark}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        RECEIVED CONFIRMATION OF STOCK LOSS AND ABNORMAL WASTAGE
                      </td>
                      <td>
                        {checkList.receivedStockLossConfirmation ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.receivedStockLossConfirmationRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.receivedStockLossConfirmationRemark}</td>
                      </tr>
                    )}

                    {/* //start here  */}
                    <tr>
                      <td>REVENUE FROM OPERATIONS</td>
                      <td>{checkList.revenueFromOperations}</td>
                    </tr>
                    <tr>
                      <td>
                        COPY OF INVOICES AT LEAST 5 TO 10 TO BE OBTAINED FOR
                        VARIOUS MONTHS FOR RECORDS
                      </td>
                      <td>{checkList.copyOfInvoices}</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>PURCHASES</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        COPY OF INVOICES AT LEAST 5 TO 10 TO BE OBTAINED FOR
                        VARIOUS MONTHS FOR RECORDS
                      </td>
                      <td>{checkList.purchaseAndInvoiceDetails}</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>SALARY</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>CHECK APPLICABILITY OF TDS</td>
                      <td>{checkList.tdsApplicabilitySalary ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>ANY SHORT/NON DEDUCTION CASES OBSERVED ?</td>
                      <td>{checkList.tdsObservations}</td>
                    </tr>

                    {checkList.tdsApplicabilitySalaryRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.tdsApplicabilitySalaryRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={2}>
                        <strong>ESI/EPF</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>TOTAL NUMBER OF EMPLOYEES</td>
                      <td>{checkList.totalEmployeesForESIEFI}</td>
                    </tr>
                    <tr>
                      <td>NUMBER OF EMPLOYEES COVERED UNDER ESI</td>
                      <td>{checkList.esiCoveredEmployees}</td>
                    </tr>
                    <tr>
                      <td>REGISTERED UNER ESI ?</td>
                      <td>{checkList.registeredUnderESI ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>REGISTERED UNER EPF ?</td>
                      <td>{checkList.registeredUnderEPF ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>ANY ESI SHORT DEDUCTION OBSERVED</td>
                      <td>{checkList.esiObservationsShort}</td>
                    </tr>
                    <tr>
                      <td>ANY ESI NON DEDUCTION OBSERVED</td>
                      <td>{checkList.esiObservationsNon}</td>
                    </tr>
                    <tr>
                      <td>ANY EPF SHORT DEDUCTION OBSERVED</td>
                      <td>{checkList.epfObservationsShort}</td>
                    </tr>
                    <tr>
                      <td>ANY EPF NON DEDUCTION OBSERVED</td>
                      <td>{checkList.epfObservationsNon}</td>
                    </tr>
                    <tr>
                      <td>
                        ANY DISALLOWANCES WRT TO THE PAYMENTS/DEDUCTIONS ?
                      </td>
                      <td>{checkList.disallowances}</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>OTHER EXPENSES</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        LIST OUT THE EXPENSES ON WHICH GST REVERSE CHARGE
                        APPLIED & ENTERED ADEQUATELY IN BOOKS OF ACCOUNTS
                      </td>
                      <td>{checkList.gstReverseChargeExpenses}</td>
                    </tr>
                    <tr>
                      <td>
                        PERSONAL NATURE/CHARITY DONATION/CLUB EXPENSES (FOR TAX
                        AUDIT PURPOSE)
                      </td>
                      <td>{checkList.personalCharityClubExpenses}</td>
                    </tr>
                    <tr>
                      <td>
                        EXAMINE EXPENSE OCCURS REGULARLY., FOR EX. ELECTRICITY
                        EXP. BOOKED EVERY MONTH OR SALARY EXP. BOOKED EVERY
                        MONTH
                      </td>
                      <td>{checkList.regularlyOccurringExpenses}</td>
                    </tr>
                    <tr>
                      <td>
                        EXAMINE WHETHER COMPANY HAD MADE EXPENSE DUE ENTRY AT
                        THE YEAR-END, FOR EX. SALARY OF MARCH MONTH BOOKED
                      </td>
                      <td>{checkList.yearEndExpenseEntries}</td>
                    </tr>
                    <tr>
                      <td>
                        CHECK ANY EXPENDITURE OF CAPITAL NATURE CHARGE IN
                        REVENUE EXPENSES
                      </td>
                      <td>{checkList.capitalNatureExpenses}</td>
                    </tr>
                    <tr>
                      <td>CHECK WHETHER GST TAX INPUT CLAIM ON ANY EXPENSES</td>
                      <td>{checkList.gstInputClaim}</td>
                    </tr>
                    <tr>
                      <td>CHECK SUCH INPUT CLAIM IN GST RETURN IN GSTR</td>
                      <td>{checkList.gstReturnGSTR}</td>
                    </tr>
                    <tr>
                      <td>COMPARE THE EXPENSES WITH PY REPORT FLUCTUATIONS</td>
                      <td>{checkList.gstReturnComparison}</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>CHANGE IN OWNERSHIP</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>ANY CHANGES DURING THE PY</td>
                      <td>
                        {checkList.changesOwnershipDuringPY ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.changesOwnershipDuringPYRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.changesOwnershipDuringPYRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>DETAILS OF SUCH CHANGES</td>
                      <td>{checkList.detailsChangesOwnership}</td>
                    </tr>
                    <tr>
                      <td>DOCUMENTS RECEIVED FOR VERIFICATION</td>
                      <td>
                        {checkList.documentsVerificationChangesOwnership
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.documentsVerificationChangesOwnershipRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {
                            checkList.documentsVerificationChangesOwnershipRemark
                          }
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>ANY SHARE TRANSFER MADE DURING THE AUDIT PERIOD</td>
                      <td>
                        {checkList.anyShareTransferAuditPeriod ? "Yes" : "No"}
                      </td>
                    </tr>

                    {checkList.anyShareTransferAuditPeriodRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.anyShareTransferAuditPeriodRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>SH4 RECEIVED</td>
                      <td>{checkList.sh4Received ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.sh4ReceivedRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.sh4ReceivedRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>NUMBER OF SHARE TRANSFERED</td>
                      <td>{checkList.noOfShareTransfer}</td>
                    </tr>
                    <tr>
                      <td>NAME OF THE TRANSFERER</td>
                      <td>{checkList.nameTransferer}</td>
                    </tr>
                    <tr>
                      <td>NAME OF THE TRANSFEREE</td>
                      <td>{checkList.nameTransferee}</td>
                    </tr>

                    <tr>
                      <td>CHANGE IN DIRECTOR</td>
                      <td>{checkList.changeInDirector ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.changeInDirectorRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.changeInDirectorRemark}</td>
                      </tr>
                    )}
                  

                    <tr>
                      <td>DPT3 APPLICABLE</td>
                      <td>{checkList.dpt3applicable ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.dpt3applicableLoan && (
                      <tr>
                        <td>LOAN AMOUNT</td>
                        <td>{checkList.dpt3applicableLoan}</td>
                      </tr>
                    )}
                  



                    <tr>
                      <td colSpan={2}>
                        <strong>CHANGE IN MANAGEMENT</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>ANY CHANGES DURING THE PY</td>
                      <td>{checkList.managementChanges ? "Yes" : "No"}</td>
                    </tr>

                    {checkList.documentsVerificationChangesOwnershipRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {
                            checkList.documentsVerificationChangesOwnershipRemark
                          }
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>DETAILS OF SUCH CHANGES</td>
                      <td>{checkList.detailsManagementChanges}</td>
                    </tr>
                    <tr>
                      <td>DOCUMENTS RECEIVED FOR VERIFICATION</td>
                      <td>
                        {checkList.verificationDocumentsManagement
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    {checkList.verificationDocumentsManagementRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>
                          {checkList.verificationDocumentsManagementRemark}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={2}>
                        <strong>GRANTS / SUBSIDIES</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>HAVE RECEIVED ANY GRANTS / SUBSIDIES ?</td>
                      <td>
                        {checkList.receivedGrantsSubsidies ? "Yes" : "No"}
                      </td>
                    </tr>
                    {checkList.receivedGrantsSubsidiesRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.receivedGrantsSubsidiesRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>WHAT IS THE NATURE OF GRANT/SUBSIDY</td>
                      <td>{checkList.natureGrantsSubsidies}</td>
                    </tr>
                    <tr>
                      <td>REFUNDABLE /NOT ?</td>
                      <td>{checkList.refundable ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.refundableRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.refundableRemark}</td>
                      </tr>
                    )}

                    <tr>
                      <td>WHAT IS THE ACCOUNTING TREATMENT ?</td>
                      <td>{checkList.accountingTreatment}</td>
                    </tr>
                    <tr>
                      <td>CONDITIONS WITH GRANTS/ SUBSIDIES</td>
                      <td>{checkList.conditions}</td>
                    </tr>
                    <tr>
                      <td>COMPLIANCE WITH THE CONDITIONS</td>
                      <td>{checkList.complianceConditions}</td>
                    </tr>
                    <tr>
                      <td>CHECK ACCOUNTING TREATMENT ON THIS</td>
                      <td>{checkList.accountingTreatmentGrants}</td>
                    </tr>

                    {/* let begin */}

                    <tr>
                      <td colSpan={2}>
                        <strong>DISPUTES AND ARBITRATIONS</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>ANY PENDING DISPUTES</td>
                      <td>{checkList.pendingDisputes ? "Yes" : "No"}</td>
                    </tr>
                    {checkList.pendingDisputesRemark && (
                      <tr>
                        <td>Remarks</td>
                        <td>{checkList.pendingDisputesRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>IF YES, DETAILED RECEIVED FOR VERIFICATION</td>
                      <td>{checkList.detailedVerification}</td>
                    </tr>
                    <tr>
                      <td>ANY OBSERVATIONS OR REMARKS</td>
                      <td>{checkList.obsRemarks_100}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ViewCheckList;
