import axios from "axios";

import { clearError, loadUserFail, loadUserRequest, loadUserSuccess, loginFail, loginRequest, loginSuccess, logOutFail, logoutSuccess, registerFail, registerRequest, registerSuccess } from "../slices/authSlice";

export const apiClient = axios.create({
  baseURL: "https://api.audit.bizpole.in",
  // baseURL: "http://localhost:8080",
  headers: {
    "Content-Type": "application/json",
  },
});

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    // const { data } = await axios.post("/api/v1/login", {
    const { data } = await apiClient.post("/api/v1/login", { email, password });
    dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(loginFail(error.response.data.message));
  }
};

export const clearAuthError = (dispatch) => {
  dispatch(clearError());
};

export const register = (userData) => async (dispatch) => {
  try {
    dispatch(registerRequest());
    // const { data } = await axios.post("/api/v1/register", { ...userData });
    const { data } = await apiClient.post("/api/v1/register", { ...userData });
    dispatch(registerSuccess(data));
  } catch (error) {
    dispatch(registerFail(error.response.data.message));
  }
};

export const loadUser = async (dispatch) => {
  try {
    dispatch(loadUserRequest());
    const { data } = await apiClient.get("/api/v1/myprofile");
    // const { data } = await axios.get("/api/v1/myprofile");
    dispatch(loadUserSuccess(data));
  } catch (error) {
    dispatch(loadUserFail(error.response.data.message));
  }
};

export const logout = async (dispatch) => {
  try {
    // await axios.get("/api/v1/logout");
    await apiClient.get("/api/v1/logout");
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logOutFail());
  }
};


// export const authenticateUser = () => {
//   return async (dispatch) => {
//     dispatch(loadUserRequest());
//     try {
//       const { data } = await apiClient.get("/api/v1/authenticate");
//       dispatch(loadUserSuccess(data));
//     } catch (error) {
//       dispatch(loadUserFail());
//     }
//   };
// };