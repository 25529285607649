import {  useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { clearAuthError, login } from "../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, isAuthenticated } = useSelector(
    (state) => state.authState
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    if (error) {
      console.log(error);
      toast(error, {
        position: "top-center",
        type: "error",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [error, isAuthenticated, dispatch, navigate]);

  return (
    <div className="LoginPage_mainDiv w-100">
      <div className="loginPageStyle row">
        <div className="col-md-8 loginPageDiv">
          <Form className="inputForm" onSubmit={submitHandler}>
            <h3 className="title_head">Login to Your Account</h3>
            <div className="inputDiv">
              <input
                className="inputField"
                type="email"
                placeholder="Email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="inputDiv">
              <input
                className="inputField"
                type={showPassword ? "text" : "password"}
                placeholder="Password.."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                className="eyeIcon"
              />
            </div>
            <button className="inputBtn" type="submit">
              LOGIN
            </button>
          </Form>
        </div>
        <div className="col-md-4 reg-section">
          <h3 className="title_head">New Here?</h3>
          <p>To set up a new account, please contact your administrator.</p>
          <button
            className="inputBtn"
            type="button"
          >
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
