import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { deleteCheckList, getCheckLists } from "../../actions/checkListActions";
import Loader from "../layouts/Loader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { clearCheckListDeleted, clearError } from "../../slices/checkListSlice";
import Search from "../layouts/Search";

function CheckList() {
  const dispatch = useDispatch();
  const { checkLists, loading, error, checkListsCount, resPerPage } =
    useSelector((state) => state.checkListsState);
  const { user } = useSelector((state) => state.authState);
  const { isCheckListDeleted, error: checkListError } = useSelector(
    (state) => state.checkListState
  );
  const [currentPage, setCurrentPage] = useState(1);

  const setCurrentPageNo = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteCheckList(id));
  };

  useEffect(() => {
    if (error || checkListError) {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 3000,
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }

    if (isCheckListDeleted) {
      toast("Checklist deleted", {
        type: "warning",
        position: "bottom-center",
        onOpen: () => {
          dispatch(clearCheckListDeleted());
        },
      });
      return;
    }

    dispatch(getCheckLists(null, currentPage));
  }, [error, dispatch, currentPage, isCheckListDeleted, checkListError]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Container style={{ minHeight: "90vh" }}>
            <div className="d-flex justify-content-end">
              <Search />
            </div>
            {checkLists &&
              checkLists.map((checkList, index) => {
                return (
                  <Card className="p-3 m-2" key={index}>
                    <Row>
                      <Col md={9} className="d-flex align-items-center">
                        <h5>{checkList.businessName}</h5>
                      </Col>

                      <Col md={3}>
                        <Link to={`/view/${checkList._id}`}>
                          <Button variant="primary">View/Edit</Button>
                        </Link>

                        {user && user.role === "admin" && (
                          <Button
                            onClick={(e) => deleteHandler(e, checkList._id)}
                            variant="danger"
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card>
                );
              })}

            {checkListsCount > 0 && checkListsCount > resPerPage ? (
              <div className="d-flex justify-content-center mt-5">
                <Pagination
                  activePage={currentPage}
                  onChange={setCurrentPageNo}
                  totalItemsCount={checkListsCount}
                  itemsCountPerPage={resPerPage}
                  // nextPageText={'Next'}
                  firstPageText={"First"}
                  lastPageText={"Last"}
                  itemClass={"page-item"}
                  linkClass={"page-link"}
                />
              </div>
            ) : null}
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
}

export default CheckList;
