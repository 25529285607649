import { createSlice } from "@reduxjs/toolkit";

const checkListsSlice = createSlice({
  name: "checkLists",
  initialState: {
    loading: false,
  },
  reducers: {
    checkListsRequest(state, action) {
      return {
        loading: true,
      };
    },
    checkListsSuccess(state, action) {
      return {
        loading: false,
        checkLists: action.payload.allCheckList,
        checkListsCount: action.payload.count,
        resPerPage: action.payload.resPerPage
      };
    },
    checkListsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = checkListsSlice;

export const { checkListsRequest, checkListsSuccess, checkListsFail } = actions;

export default reducer;