import React from "react";

export default function Footer() {
  return (
    <footer className="py-3 footer_set">
      <p className="text-center text-dark mt-1">
        Bizpole - &copy;2024, All Rights Reserved
      </p>
    </footer>
  );
}
